import { Outlet, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import BreadcrumbComponent from "@/components/Breadcrumb/Breadcrumb";
import RelatedArticlesComponent from "@/components/About/JournalismDetail/components/RelatedArticles";
import { useArticleState } from "@/stores/useArticleState";
import { useEffect, useState } from "react";

interface BreadcrumbItem {
  text: string;
  href: string;
}

const ArticleLayout = () => {
  const { articleDetail } = useArticleState();
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([
    { text: "Trang chủ", href: "/" },
    { text: "Về FinHub", href: "/about/journalist-and-communication" },
  ]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(() => {
    if (articleDetail) {
      setBreadcrumbItems([
        { text: "Trang chủ", href: "/" },
        { text: "Về FinHub", href: "/about/journalist-and-communication" },
        { text: articleDetail.title, href: `/article/${articleDetail.id}` },
      ]);
    }
  }, [articleDetail]);

  return (
    <>
      <Container fluid className={`mt-4 mb-5 ${!isMobile ? 'px-5' : ''}`}>
        <Row className="g-5">
          <Col xs={12} md={12} lg={12} xl={8} xxl={8} >
            <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />
            <Outlet />
          </Col>
          <Col xs={12} md={12} lg={12} xl={4} xxl={4}>
            <RelatedArticlesComponent />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ArticleLayout;
