import { useState, useEffect } from "react";
import "./SearchPositionJob.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ApiServices from "@/services/api.ts";
import Utils from "@/utils";
const apiServices = new ApiServices();
const utils = new Utils();
function SearchPositionJob({ updateJobDetail }) {
  useEffect(() => {
    gelAllTag();
    gelAllJob();
  }, []);

  const [categories, setCategories] = useState([]);
  const [results, setResults] = useState([]);
  const [searchKey, setSearchKey] = useState('')
  const [activeTag, setActiveTag] = useState('')

  async function onClickCategoryHandler(tagName) {
    setSearchKey('')
    if (tagName === "all") {

      
      setActiveTag('')
      gelAllJob();
      return;
    }
    setActiveTag(tagName)
    const payload = {
      tag: tagName.substring(1),
      search: searchKey
    };
    const res = await apiServices.executeGetRequest("/job", payload);
    setResults(res.data.data.data);
  }

  function onClickViewDetail(item) {
    updateJobDetail(item);
  }

  async function gelAllJob() {
    const res = await apiServices.executeGetRequest("/job", {});
    setResults(res.data.data.data);
  }
  async function gelAllTag() {
    const res = await apiServices.executeGetRequest("/job/tags", {});
    setCategories(res.data.data.data);
  }

  async function searchJobHandler() {
    const payload = {
      search: searchKey,
      tag: activeTag
    };
    const res = await apiServices.executeGetRequest("/job", payload);
    setResults(res.data.data.data);
  }

  return (
    <>
      <div className="d-grid gap-4 mt-5">
        <div className="search-position-title primary-color text-center">
          Các vị trí cần tuyển
        </div>
        <div className="d-flex justify-content-center">
          <InputGroup className="mb-3 about-search-position-group">
            <Form.Control
              className="about-input-search"
              placeholder="Tìm vị trí ứng tuyển"
              aria-label="Tìm vị trí ứng tuyển"
              aria-describedby="basic-addon2"
              value={searchKey}
              onChange={($event) =>  setSearchKey($event.target.value)}
            />
            <Button
            onClick={searchJobHandler}
              className="about-btn-search"
              variant="outline-secondary"
              id="button-addon2"
            >
              <img
                className="img-fluid"
                src={
                  require("@/assets/images/icon/svg/search-white.svg").default
                }
              />
            </Button>
          </InputGroup>
        </div>

        <div className="d-flex gap-3" style={{ flexFlow: "row wrap" }}>
          <div
            onClick={() => onClickCategoryHandler("all")}
            className={"about-tag-container " + (!activeTag ? 'active' : '')}
            style={{ textTransform: "capitalize" }}
          >
            #All
          </div>
          {categories.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => onClickCategoryHandler(item)}
                className={"about-tag-container " + (item === activeTag ? 'active' : '')}
                style={{ textTransform: "capitalize" }}
              >
                {utils.convertToTitleCase(item)}
              </div>
            );
          })}
        </div>

        <div className="containter-fluid about-search-results-container d-grid gap-3">
          {results.map((item, index) => {
            return (
              <div key={index} className="row" style={{ padding: "10px" }}>
                <div className="col-xs-5 col-md-5 col-lg-4">
                  <img
                    src={
                      require("@/assets/images/icon/svg/circle-dot.svg").default
                    }
                  />
                  <span className="ms-2 job-search-result-title">
                    {item.title}
                  </span>
                </div>
                <div className="col-xs-2 col-md-2 col-lg-4 text-center job-search-result-role">
                  {item.level}
                </div>
                <div className="col-xs-5 col-md-5 col-lg-4 text-end primary-color job-search-result-btn">
                  <span
                    onClick={() => onClickViewDetail(item)}
                    style={{ cursor: "pointer" }}
                  >
                    XEM THÊM
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SearchPositionJob;
