import React from "react";
import "./Card.css";
import { Link } from "react-router-dom";
import { icons } from "@/utils/icons";

import moment from "moment";

const { CalendarSvg, AuthorSvg } = icons;

const Card = ({
  thumbnail,
  category,
  title,
  description,
  author,
  createdAt,
  isSmall,
  id,
}) => {
  return (
    <Link
      to={`/blog/details/${id}`}
      className={` ${isSmall ? "card_blog_small" : "card_blog"} `}
      title={title}
    >
      {/* Thumnail */}
      <div
        className={`card_blog_thumbnail ${
          isSmall && "card_blog_thumbnail_small"
        }`}
      >
        <img src={thumbnail} alt={title} />
      </div>

      {/* Infor */}

      <div className={`card_blog_infor ${isSmall && "card_blog_infor_small"}`}>
        {/* Category */}
        <div
          className={`card_blog_category color_primary ${
            isSmall && "card_blog_category_small"
          }`}
        >
          <p className="text_regular color_primary">{category}</p>
        </div>

        {/* Title */}
        <div className="card_blog_title">
          <h2 className="headline_small color_primary">{title}</h2>
        </div>

        {/* Description */}
        <div className="card_blog_description">
          <p className="text_regular color_primary">{description}</p>
        </div>

        {/* Author and createdAt */}

        <div className="card_blog_author">
          <div>
            <AuthorSvg />
            <p className="color_gray3 text_regular">{author}</p>
          </div>
          <div>
            <CalendarSvg />
            <p className="color_gray3 text_regular">
              {moment(createdAt).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
