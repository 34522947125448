import React from "react";

import "./Classify.css";
import TitleBorderGradient from "../TitleBorderGradient/TitleBorderGradient";
import ClassifyList from "./ClassifyList";
import { useGetAllPost } from "@/hook/blog/useGetAllPost";

const Classify = ({ list }) => {
  const { data } = useGetAllPost();

  return (
    <div className="classify">
      <TitleBorderGradient
        title="PHÂN LOẠI"
        className="title_large color_primary"
      />

      <ClassifyList list={data} />
    </div>
  );
};

export default Classify;
