import React, { useEffect, useState } from "react";
import "./News.css";
import { icons } from "@/utils/icons";
import CardNew from "../_components/CardNew/CardNew";

const { SearcSvg } = icons;

const ListNews = ({ data }) => {
  const [dataList, setDataList] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setDataList(data);
  }, [data]);

  const handleSearch = (e) => {
    e.preventDefault();

    const results = data?.filter((item) =>
      item?.attributes?.title?.toLowerCase().includes(searchText.toLowerCase())
    );

    setDataList(results);
  };

  return (
    <div className="list_new">
      <h2 className="display_large text_center color_primary list_new_title">
        Tin tức
      </h2>

      <form onSubmit={handleSearch} className="list_new_form">
        <div className="list_new_form_inpput">
          <label htmlFor="search_new">
            <SearcSvg />
          </label>
          <input
            type="text"
            id="search_new"
            name="search_new"
            placeholder="Tìm kiếm tin tức"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <button type="submit" className="list_new_form_btn">
          Tìm kiếm
        </button>
      </form>

      <div className="list_new_list">
        {dataList?.map((item) => (
          <CardNew
            key={item?.id}
            thumbnail={item?.attributes?.thumbnail?.data?.attributes?.url}
            category={item?.attributes?.sub_category?.data?.attributes?.name}
            author={item?.attributes?.author}
            createdAt={item?.attributes?.createdAt}
            description={item?.attributes?.title}
            title={item?.attributes?.title}
            id={item?.id}
          />
        ))}
      </div>
    </div>
  );
};

export default ListNews;
