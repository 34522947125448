import "./Introduction.css";
function Introduction() {
  const cards = [
    {
      title: "Đầu tư thông minh hơn",
      image: "business-idea-money.svg",
      content:
        "Cung cấp kiến thức từ cơ bản đến nâng cao giúp người dùng xây dựng kế hoạch tài chính hợp lý hơn.",
    },
    {
      title: "Đầu tư hiệu quả hơn",
      image: "business-sale.svg",
      content:
        "Cung cấp đa dạng công cụ hỗ trợ quyết định tài chính.",
    },
    {
      title: "Đầu tư dễ dàng hơn",
      image: "business-money.svg",
      content:
        "Ứng dụng được hoàn thiện với đầy đủ các tính năng giúp người dùng có thể giao dịch trên điện thoại để có thể sử dụng mọi lúc mọi nơi.",
    },
  ];
  return (
    <>
      <div>
        <div>
          <p className="about-title primary-color">Giới thiệu</p>
        </div>
        <div>
          <p>
            Công ty cổ phần Công nghệ Tài chính FinHub hay FinHub FT JSC được
            thành lập từ năm 2022 và đã đăng ký với giấy ĐKKD số 0110122755 do
            Sở Kế hoạch & Đầu tư Hà Nội cấp ngày 20/09/2022.
          </p>
          <p>
            FinHub là công ty khởi nghiệp tiên phong của Việt Nam trong việc
            cách mạng hóa thế giới đầu tư. Với chúng tôi, không có "một kích cỡ
            phù hợp cho tất cả". Mỗi kế hoạch tài chính đều được tạo ra đặc biệt
            cho bạn - để đảm bảo rằng mọi bước di chuyển đều đưa bạn gần hơn đến
            ước mơ tài chính của mình. Hãy bắt đầu hành trình của bạn với chúng
            tôi và cùng nhau, chúng ta sẽ xây dựng một tương lai tài chính mạnh
            mẽ và bền vững.
          </p>
        </div>

        <div className="container-fluid p-0">
          <div className="row introduct-card-container">
            {cards.map((item, index) => {
              return (
                <div className="col-xs-12 col-md-4 p-3">
                  <div key={index} className="introduce-card text-center">
                    <p className="introduce-card-title">{item.title}</p>
                    <img
                      className="introduce-card-image"
                      src={require(`@/assets/images/icon/svg/${item.image}`)}
                    />
                    <p className="introduce-card-text">{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Introduction;
