import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./styles/global.css";
import Home from "@/views/Home/Home.jsx";
// import Header from "@/components/Header/Header.jsx";
import Layout from "@/components/Layout.jsx";
import About from "@/views/About/About";
import Slogan from "@/components/About/Slogan/Slogan";
import CareerOpportunities from "./components/About/CareerOpportunities/CareerOpportunities";
import CoreValues from "./components/About/CoreValues/CoreValues";
import Introduction from "./components/About/Introduction/Introduction";
import ManagementTeam from "./components/About/ManagementTeam/ManagementTeam";
import PartnersAndSupportingUnits from "./components/About/PartnersAndSupportingUnits/PartnersAndSupportingUnits";
import AboutProduct from "./components/About/Product/Product";
import AboutLayout from "@/Layout/AboutLayout";
import Product from "@/views/Product/Product";
import System from "@/views/System/System";
import Support from "@/views/Support/Support";

import ProductLayout from "./Layout/ProductLayout";
import Blog from "@/components/Blog/Blog";
import ChungChiQuyMo from "@/components/Product/ChungChiQuyMo/ChungChiQuyMo";
import RoboAdvisor from "@/components/Product/RoboAdvisor/RoboAdvisor";
import ThuatNgu from "@/components/Product/ThuatNgu/ThuatNgu";
import ChungChiQuyMoDetail from "./components/Product/ChungChiQuyMoDetail/ChungChiQuyMoDetail";

import ComingSoon from "./components/ComingSoon/ComingSoon";
import NotFound from "./components/NotFound/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import SupportLayout from "./Layout/SupportLayout";
import FrequentlyAskedQuestions from "./components/Support/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import TermsOfUse from "./components/Support/TermsOfUse/TermsOfUse";

import LayoutBlogPage from "./components/LayoutBlogPage";
import Latest from "./components/Blog/Latest/Latest";
import News from "./components/Blog/News/News";
import Details from "./components/Blog/Details/Details";
import DM1 from "./components/Blog/DM/DM1";
import DM2 from "./components/Blog/DM/DM2";
import DM3 from "./components/Blog/DM/DM3";
import CcqmCungFinHub from "./components/ChungChiQuyMo/CcqmCungFinHub/CcqmCungFinHub";
import MoPhongDauTuQuyMo from "./components/ChungChiQuyMo/MoPhongDauTuQuyMo/MoPhongDauTuQuyMo";
import KienThuc from "./views/KienThuc/KienThuc";
import CongCu from "./views/CongCu/CongCu";
import JournalismCommunication from "./components/About/JournalismCommunication";
import ArticleLayout from "./Layout/ArticleLayout";
import JournalismDetail from "./components/About/JournalismDetail";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { useGetCategoryArticle } from "./services/new/apiMutations/article.mutation";
import { useEffect } from "react";
const queryClient = new QueryClient();

function RootApp() {
  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
}

function App() {
  const { mutate: requestGetCategory } = useGetCategoryArticle();

  useEffect(() => {
    requestGetCategory();
  },[]);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          {/* Main layout */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<AboutLayout />}>
              <Route path="" element={<Slogan />} />
              <Route path="slogan" element={<Slogan />} />
              <Route path="gioi-thieu" element={<Introduction />} />
              <Route path="doi-ngu-quan-ly" element={<ManagementTeam />} />
              <Route path="gia-tri-cot-loi" element={<CoreValues />} />
              <Route path="san-pham" element={<AboutProduct />} />
              <Route
                path="doi-tac-va-don-vi-ho-tro"
                element={<PartnersAndSupportingUnits />}
              />
              <Route
                path="co-hoi-nghe-nghiep"
                element={<CareerOpportunities />}
              />
              <Route
                path="journalist-and-communication"
                element={<JournalismCommunication />}
              />
            </Route>
            <Route path="article" element={<ArticleLayout />}>
              <Route path=":id" element={<JournalismDetail />} />
            </Route>
            <Route path="product" element={<ProductLayout />}>
              <Route path="" element={<Product />} />
              <Route path="chung-chi-quy-mo">
                <Route path="" element={<ChungChiQuyMo />} />
                <Route path="detail" element={<ChungChiQuyMoDetail />} />

                <Route path="ccqm-cung-finhub" element={<CcqmCungFinHub />} />
              </Route>
              {/* <Route path="robo-advisor" element={<RoboAdvisor />} /> */}
              <Route path="blog" element={<Blog />} />
              <Route path="thuat-ngu" element={<ThuatNgu />} />
            </Route>
            <Route path="system-finhub" element={<System />} />
            <Route path="support" element={<SupportLayout />}>
              <Route index element={<FrequentlyAskedQuestions />} />
              <Route
                path="cau-hoi-thuong-gap"
                element={<FrequentlyAskedQuestions />}
              />
              <Route path="dieu-khoan-dich-vu" element={<TermsOfUse />} />
            </Route>
            <Route path="kien-thuc">
              <Route index element={<KienThuc />} />
              <Route path="thuat-ngu" element={<ThuatNgu />} />
            </Route>
            <Route path="cong-cu">
              <Route index element={<CongCu />} />
              <Route path="robo-advisor" element={<RoboAdvisor />} />
              <Route
                path="mo-phong-dau-tu-quy-mo"
                element={<MoPhongDauTuQuyMo />}
              />
            </Route>
            {/* <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
          </Route>
          {/* Blog layout */}
          <Route path="/blog" element={<LayoutBlogPage />}>
            <Route index element={<Blog />} />
            <Route path="latest" element={<Latest />} />
            <Route path="details/:id" element={<Details />} />
            <Route path="/blog/:dm1" element={<DM1 />} />
            <Route path="/blog/:dm1/:dm2" element={<DM2 />} />
            <Route path="/blog/:dm1/:dm2/:dm3" element={<DM3 />} />
            <Route path="news" element={<News />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default RootApp;
