import "./PartnersAndSupportingUnits.css";
import BackgroundImage from "@/assets/images/picture/png/about-picture1.png";
function PartnersAndSupportingUnits() {
  return (
    <>
      <div>
        <div>
          <p className="about-title primary-color">Đối tác & Đơn vị hỗ trợ</p>
        </div>
        <div>
          <p>
            Chúng tôi, tại FinHub, không thể nhấn mạnh đủ tầm quan trọng về sự
            hỗ trợ của các đối tác trong suốt hành trình phát triển của chúng
            tôi. Chúng tôi cam kết tiếp tục xây dựng mối quan hệ tích cực với
            các đối tác để FinHub ngày càng trở thành một nền tảng đáng tin cậy
            và hữu ích cho cộng đồng tài chính và doanh nghiệp.
          </p>
        </div>

        <div
          className="about-partner-bg mt-3"
          style={{
            // backgroundImage: `url(${BackgroundImage})`,
            // height: "550px",
            position: "relative",
            // backgroundSize: "cover"
          }}
        >
          {/* <div className="about-partner-card card-1">
            <div>
              <img
                height={50}
                src={require("@/assets/images/picture/png/partner1.png")}
              />
            </div>
            <div>
              <p
                className="text-center"
                style={{ fontSize: "14px", lineHeight: "30px" }}
              >
                Trung tâm Đổi mới sáng tạo Quốc gia Việt Nam là đơn vị trực
                thuộc Bộ Kế hoạch và Đầu tư.
              </p>
            </div>
          </div>
          <div className="about-partner-card card-2">
            <div>
              <img
                height={50}
                src={require("@/assets/images/picture/png/partner2.png")}
              />
            </div>
            <div>
              <p
                className="text-center"
                style={{ fontSize: "14px", lineHeight: "30px" }}
              >
                Amazon Web Services (AWS) là giải pháp đám mây toàn diện và được
                sử dụng rộng rãi nhất.
              </p>
            </div>
          </div>
          <div className="about-partner-card card-3">
            <div>
              <img
                height={50}
                src={require("@/assets/images/picture/png/partner3.png")}
              />
            </div>
            <div>
              <p
                className="text-center"
                style={{ fontSize: "14px", lineHeight: "30px" }}
              >
                BLOCK71 là một sáng kiến ​​toàn cầu của NUS Enterprise phối hợp
                với các doanh nghiệp và cơ quan chính phủ lâu đời.
              </p>
            </div>
          </div> */}
          <div className="container">
            <div className="row gy-3 justify-content-md-center">
              <div className="col-xs-12 col-md-4">
                <div className="about-partner-card">
                  <div>
                    <img
                      height={50}
                      src={require("@/assets/images/picture/png/partner1.png")}
                    />
                  </div>
                  <div>
                    <p
                      className="text-justify "
                      style={{ fontSize: "14px", lineHeight: "30px" }}
                    >
                      Trung tâm Đổi mới sáng tạo Quốc gia Việt Nam là đơn vị
                      trực thuộc Bộ Kế hoạch và Đầu tư.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div className="about-partner-card">
                  <div>
                    <img
                      height={50}
                      src={require("@/assets/images/picture/png/partner2.png")}
                    />
                  </div>
                  <div>
                    <p
                      className="text-justify"
                      style={{ fontSize: "14px", lineHeight: "30px" }}
                    >
                      Amazon Web Services (AWS) là giải pháp đám mây toàn diện
                      và được sử dụng rộng rãi nhất.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div className="about-partner-card">
                  <div>
                    <img
                      height={50}
                      src={require("@/assets/images/picture/png/partner3.png")}
                    />
                  </div>
                  <div>
                    <p
                      className="text-justify"
                      style={{ fontSize: "14px", lineHeight: "30px" }}
                    >
                      BLOCK71 là một sáng kiến ​​toàn cầu của NUS Enterprise
                      phối hợp với các doanh nghiệp và cơ quan chính phủ lâu
                      đời.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div className="about-partner-card">
                  <div>
                    <img
                      height={50}
                      src={require("@/assets/images/picture/png/techcombank_logo.png")}
                    />
                  </div>
                  <div>
                    <p
                      className="text-justify"
                      style={{ fontSize: "14px", lineHeight: "30px" }}
                    >
                      Techcombank là ngân hàng số hàng đầu Việt Nam.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div className="about-partner-card">
                  <div>
                    <img
                      height={50}
                      src={require("@/assets/images/icon/jpg/google-workspace-logo.jpg")}
                    />
                  </div>
                  <div>
                    <p
                      className="text-justify"
                      style={{ fontSize: "14px", lineHeight: "30px" }}
                    >
                      Google Workspace là một bộ ứng dụng năng suất điện toán đám mây và các công cụ phần mềm cộng tác và phần mềm được cung cấp bởi Google trên cơ sở đăng ký thuê bao.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnersAndSupportingUnits;
