import React from "react";
import "./Layout.css";
import BoxRightRegister from "../BoxRightRegister/BoxRightRegister";
import OutstandingBox from "../OutstandingBox/OutstandingBox";

const Layout = ({
  children,
  listOutstandingBox = [],
  isOutstandingBox = true,
}) => {
  return (
    <div className="container layout_page_blog">
      {/* breadcrumb */}
      {/* Content */}
      <div className="layout_page_blog_inner">
        {/* Left */}
        <div className="layout_page_blog_inner_left">
          {children}

          <div className="layout_page_blog_mobile">
            <BoxRightRegister />
            {isOutstandingBox && <OutstandingBox list={listOutstandingBox} />}
          </div>
        </div>

        {/* Right */}

        <div className="layout_page_blog_inner_right">
          <BoxRightRegister />
          {isOutstandingBox && <OutstandingBox list={listOutstandingBox} />}
        </div>
      </div>
    </div>
  );
};

export default Layout;
