import React from "react";
import styles from "./style.module.css";
import { useArticleState } from "@/stores/useArticleState";
interface FilterTopicItemType {
  id: number;
  name: string;
  selectedTopic: number | null;
  onSelect: (id: number) => void;
}

function FilterTopicItem({
  id,
  name,
  selectedTopic,
  onSelect,
}: FilterTopicItemType) {
  return (
    <div
      key={id}
      onClick={() => onSelect(id)}
      className={
        selectedTopic === id
          ? styles.filter_topic_item_selected
          : styles.filter_topic_item
      }
    >
      {name}
    </div>
  );
}

interface FilterTopicComponentProps {
  selectedTopic: number | null;
  onSelect: (id: number) => void;
}

export default function FilterTopicComponent({
  selectedTopic,
  onSelect,
}: FilterTopicComponentProps) {
  const { categoryList } = useArticleState();

  return (
    <div className={styles.container}>
      <p className={styles.title}>Chủ đề bài viết</p>
      <div className={styles.filter_topic}>
        {categoryList.map((item) => (
          <FilterTopicItem
            key={item.id}
            {...item}
            selectedTopic={selectedTopic}
            onSelect={onSelect}
          />
        ))}
      </div>
    </div>
  );
}
