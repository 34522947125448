import "./Slogan.css";
import styles from "./Slogan.module.css";
function Slogan() {
  return (
    <div className="d-grid gap-5 mb-5">
      <div className={styles.session1_container}>
        <img
          className="img-fluid"
          src={require("@/assets/images/picture/jpg/about-slogan.jpg")}
        />

        {/* <div className={styles.session1_card}>
          <div className={styles.title}>Financial For Life!</div>
          <div className={styles.text}>
            Tài chính chưa bao giờ quan trọng đến như vậy trong cuộc sống hàng
            ngày của chúng ta. Khả năng quản lý và sử dụng thông tin tài chính
            một cách thông minh không chỉ ảnh hưởng đến khía cạnh vật chất mà
            còn đến chất lượng và hạnh phúc của cuộc sống.
          </div>
        </div> */}
      </div>

      <div className="slogon-session3-container">
        <div className="slogon-session3-header">
          {/* <img
            style={{ margin: "auto" }}
            width={100}
            className="img-fluid"
            src={require("@/assets/images/picture/png/rocket.png")}
          /> */}
          <div className="title primary-color">Sứ Mệnh</div>
        </div>
        <div>
          <div className="text">
            <p>
              Mở khóa cơ hội đầu tư cho mọi
              người và khuyến khích sự phát triển tài chính cho tất cả. Chúng
              tôi tạo ra một môi trường nơi mà nhà đầu tư mới bắt đầu hay đã có
              nhiều kinh nghiệm đều có thể đầu tư hiệu quả. Chúng tôi tin rằng
              mọi người đều có cơ hội bình đẳng để đầu tư một cách đúng cách,
              nhằm xây dựng một tương lai tốt đẹp hơn.
            </p>
          </div>

          <div className="text">
            <p>
              Tại FinHub, chúng tôi hướng tới việc tạo ra trải nghiệm này một
              cách linh hoạt nhất bằng cách cung cấp một nền tảng thân thiện với
              người dùng, đầy đủ tính năng và an toàn. Đội ngũ chúng tôi cam kết
              hỗ trợ liên tục, thường xuyên chia sẻ các kiến thức, giúp chúng
              tôi thực hiện sứ mệnh mở khóa và nuôi dưỡng sự phát triển tài
              chính của bạn.
            </p>
          </div>
        </div>
      </div>

      <div>
        {/* PC session */}
        <div className="about-slogan-session2">
          <p className="about-slogan-text">
            <span>Cùng FinHub</span> lập kế hoạch tài
          </p>
          <p className="about-slogan-text">
            chính riêng cho bạn{" "}
            <img
              src={
                require("@/assets/images/icon/svg/left-check-mark.svg").default
              }
              width={28}
              height={28}
            />{" "}
          </p>
        </div>

        {/* Mobile session */}
        <div className="about-slogan-session2-mb">
          <p className="about-slogan-text">
            <span>Cùng FinHub</span> lập kế hoạch tài chính riêng cho bạn{" "}
            <img
              src={
                require("@/assets/images/icon/svg/left-check-mark.svg").default
              }
              width={28}
              height={28}
            />
          </p>
        </div>

        <div className="about-slogan-session3">
          <img
            className="img-fluid"
            src={require("@/assets/images/picture/png/about-picture.png")}
          />
        </div>
      </div>
    </div>
  );
}

export default Slogan;
