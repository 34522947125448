import { Outlet, Link } from "react-router-dom";

const ProductLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default ProductLayout;
