import "./JobDetail.css";
import FormRegister from "@/components/About/FormRegister/FormRegister";
import Utils from "@/utils";
import parse from 'html-react-parser';
function JobDetail({ jobDetail, updateJobDetail }) {
  console.log(jobDetail);
  return (
    <>
      <div className="d-grid gap-4 ">
        <button onClick={() => updateJobDetail(null)} className="btn btn-secondary" style={{width: 'fit-content', border: 0,}}>Quay Lại</button>
        {/* <div>
          <button className="jobdetail-title">{jobDetail.title}</button>
        </div>

        <div className="d-flex justify-content-start gap-3">
          <div>
            <img
              src={
                require("@/assets/images/icon/svg/location-blue.svg").default
              }
              alt="location blue"
            />
            <span className="ms-1">Vị trí: {jobDetail.location}</span>
          </div>
          <div>
            <img
              src={require("@/assets/images/icon/svg/star-blue.svg").default}
              alt="star blue"
            />
            <span className="ms-1">Cấp độ: {jobDetail.level}</span>
          </div>
          <div>
            <img
              src={
                require("@/assets/images/icon/svg/location-blue.svg").default
              }
              alt="location blue"
            />
            <span className="ms-1">Thời hạn: {new Utils().formatDateToDDMMYYYY(jobDetail.apply_deadline)}</span>
          </div>
        </div>

        <div className="jobdetail-hr" />

        {jobDetail.job_description.map((item, index) => {
          return (
            <div key={index}>
              <p className="jobdetail-desc-title">
                {index + 1}
                {". "}
                {item.title}
              </p>
              <ul
                id="job-detail"
                style={{ listStyleColor: "red", display: "grid", gap: "15px" }}
              >
                {item.desc.map((descItem, descIndex) => {
                  return <li key={descIndex}>{descItem}</li>;
                })}
              </ul>
            </div>
          );
        })} */}

        <div>
          {
            parse(jobDetail.job_description)
          }
        </div>

        <FormRegister jobDetail={jobDetail} />
      </div>
    </>
  );
}

export default JobDetail;
