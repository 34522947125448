import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import HeaderBlog from "./HeaderBlog/HeaderBlog";
import FooterBlog from "./FooterBlog/FooterBlog";

const LayoutBlogPage = () => {
  return (
    <>
      {/* Header */}
      <HeaderBlog />

      {/* Content */}
      <div style={{ minHeight: "70vh" }}>
        <Suspense fallback={null}>
          <Outlet />
        </Suspense>
      </div>

      {/* Footer */}
      <FooterBlog />
    </>
  );
};

export default LayoutBlogPage;
