import React from "react";
import Layout from "../_components/Layout/Layout";
import BoxTitlePage from "../_components/BoxTitlePage/BoxTitlePage";
import GroupBoxNavigateWhite from "../_components/GroupBoxNavigateWhite/GroupBoxNavigateWhite";
import { useGetCategoryByIdApi } from "@/hook/blog/usegetCategoryByIdApi";
import Loading from "../_components/Loading/Loading";
import Ricktext from "../_components/Ricktext/Ricktext";

const DM1 = () => {
  const { data, loading } = useGetCategoryByIdApi();

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Layout isOutstandingBox={false}>
        <div className="financial_parameters_page">
          <BoxTitlePage
            title={data?.attributes?.name}
            subTitle={data?.attributes?.short_description}
          />

          <GroupBoxNavigateWhite list={data?.attributes?.dm_cap_2s?.data} />

          <div style={{ margin: "60px 0" }}>
            <Ricktext content={data?.attributes?.detail} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DM1;
