export const icons = {
    arrowDown : require('@/assets/images/icon/ic_arrow_down.svg').default,
    arrowDownGradient : require('@/assets/images/icon/ic_arrow_down_gradient.svg').default,
    author: require('@/assets/images/icon/ic_author.svg').default,

    calendar: require('@/assets/images/icon/ic_calendar.svg').default,
    divider : require('@/assets/images/icon/ic_divider.png'),

    shareFacebook : require('@/assets/images/icon/ic_share_facebook.svg').default,
    shareTwitter : require('@/assets/images/icon/ic_share_twitter.svg').default,
    shareLinkedin : require('@/assets/images/icon/ic_share_linkedin.svg').default,
    shareInstagram : require('@/assets/images/icon/ic_share_instagram.svg').default,
}