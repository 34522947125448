import "./TermsOfUse.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
function TermsOfUse() {
  return (
    <div id="term-of-use">
      <Tabs
        defaultActiveKey="service"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="service" title="Điều khoản dịch vụ">
          <div style={{ textAlign: "justify" }}>
            <div>
              <p>
                <strong>I. Điều khoản sử dụng</strong>
              </p>
              <p>
                Website <a style={{display: 'inline'}} href="https://www.finhub.com.vn/" target="_blank">https://www.finhub.com.vn/</a> và ứng dụng FinHub (“FinHub”)
                được sở hữu/sử dụng và vận hành bởi Công ty cổ phần Công nghệ
                Tài chính FinHub (“Công ty”).
              </p>
              <p>
                Bằng việc truy cập hoặc sử dụng FinHub, người dùng đã đồng ý
                chấp nhận, tuân thủ và bị ràng buộc bởi các điều khoản và điều
                kiện sử dụng được nêu dưới đây. Người dùng vui lòng đọc kỹ toàn
                bộ các điều khoản sử dụng trước khi sử dụng FinHub.
              </p>
            </div>

            <div>
              <p>
                <strong>II. Thỏa thuận ràng buộc</strong>
              </p>
              <p>
                Bằng việc truy cập và tham gia hoạt động trên FinHub, đồng nghĩa
                với người dùng đồng ý chấp nhận và bị ràng buộc bởi tất cả nội
                dung mà Công ty đưa ra dưới đây. Các quy định trong Bản Điều
                khoản sử dụng này được đăng tải công khai trên FinHub và có giá
                trị ràng buộc tương đương một Hợp đồng được ký kết giữa Công ty
                và bạn cũng như với các người dùng khác liên quan tới mọi hoạt
                động trên FinHub. Trong bất cứ trường hợp sai phạm hoặc tranh
                chấp nào phát sinh từ việc bạn không thực hiện đúng và đủ theo
                các khoản thỏa thuận dưới đây, bạn phải chịu hoàn toàn trách
                nhiệm trước Công ty, bên thứ ba có quyền lợi liên quan hoặc
                trước pháp luật.
              </p>
              <p>
                Công ty có thể cập nhật điều khoản này bất cứ lúc nào để tuân
                thủ các quy định pháp luật và cho phép FinHub hoạt động tốt hơn.
                Mọi sự thay đổi, bổ sung và cập nhật các điều khoản sẽ được gửi
                thông báo cho người dùng thông qua tài khoản của người dùng trên
                FinHub hoặc gửi đến địa chỉ email mà người dùng đã cung cấp cho
                chúng tôi khi đăng ký tài khoản hoặc trên các thông báo điện tử
                của FinHub.
              </p>
            </div>

            <div>
              <p>
                <strong>III. Sử dụng FinHub</strong>
              </p>
              <p>
                Công ty chỉ cung cấp cho người dùng có quyền giới hạn, không độc
                quyền để truy cập và sử dụng FinHub cho việc sử dụng cá nhân của
                người dùng và/hoặc cho phép người dùng tiếp cận với các sản phẩm
                tài chính.
              </p>
              <p>
                <strong>Người dùng đồng ý không thực hiện các điều sau:</strong>
              </p>
              <ol>
                <li>
                  Tập hợp, sao chép hoặc nhân bản bất kỳ nội dung hoặc thông tin
                  nào của FinHub mà không có sự cho phép và chấp thuận của Công
                  ty.{" "}
                </li>
                <li>
                  Liên kết đến bất kỳ nội dung hoặc thông tin có sẵn trên FinHub{" "}
                </li>
                <li>
                  Người dùng không được quyền bán lại hoặc chuyển nhượng các
                  quyền và nghĩa vụ của người dùng theo Điều khoản này.{" "}
                </li>
                <li>
                  Sử dụng các dịch vụ trên FinHub cho bất kỳ các hoạt động nào
                  có mục đích và tính chất vi phạm pháp luật và bất hợp pháp.
                </li>
              </ol>

              <p>
                <strong>Mật khẩu và bảo vệ mật khẩu:</strong>
              </p>
              <p>
                Khi người dùng đăng ký tài khoản tại FinHub, người dùng sẽ được
                yêu cầu tạo mật khẩu. Người dùng phải có trách nhiệm giữ mật
                khẩu được bảo mật và không được tiết lộ hoặc chia sẻ thông tin
                mật khẩu cho bất kỳ ai khác. Trong trường hợp người dùng biết
                hoặc nghi ngờ thông tin mật khẩu bị lộ, bị đánh cắp thì người
                dùng vui lòng thông báo, liên hệ cho Công ty ngay lập tức.
              </p>
              <p>
                Trong trường hợp Công ty có cơ sở tin rằng khả năng có hành vi
                gian lận vi phạm bảo mật hoặc sử dụng FinHub không đúng mục
                đích, Công ty có quyền yêu cầu người dùng thay đổi mật khẩu hoặc
                có thể tạm dừng tài khoản của người dùng.
              </p>
              <p>
                <strong>
                  Nếu trong trường hợp người dùng mất mật khẩu, hoặc sử dụng
                  FinHub không đúng mục đích:
                </strong>
              </p>
              <ol>
                <li>
                  Người dùng phải nhận trách nhiệm chịu tất cả sự tổn thất,
                  thiệt hại và chi phí phát sinh.
                </li>
                <li>
                  Người dùng phải chịu trách nhiệm bồi thường cho Công ty trong
                  trường hợp Công ty có thể bị thiệt hại hoặc mất mát.
                </li>
              </ol>

              <p>
                <strong>Thông tin người dùng:</strong>
              </p>
              <p>
                Người dùng hiểu rằng tất cả thông tin người dùng thuộc trách
                nhiệm duy nhất của người tạo ra thông tin người dùng này. Người
                dùng hiểu và thừa nhận rằng tất cả thông tin do người dùng cung
                cấp và thông tin tài khoản của người dùng sẽ được lưu trữ tại cơ
                sở dữ liệu của FinHub. Người dùng hiểu, thừa nhận và đồng ý rằng
                tất cả thông tin do người dùng cung cấp và thông tin tài khoản
                của người dùng có thể được chuyển sang các nước bên ngoài nơi cư
                trú của người dùng nhằm mục đích lưu trữ và/hoặc xử lý thông
                tin. FinHub không đảm bảo tính trung thực, chính xác, độ tin cậy
                của thông tin người dùng hoặc bất kỳ thông tin liên lạc khác
                được cung cấp bởi người dùng cũng như không xác nhận bất kỳ ý
                kiến nào của người dùng.
              </p>

              <p>
                <strong>Các điều khoản bổ sung áp dụng cho người dùng:</strong>
              </p>
              <p>
                Nền tảng cung cấp cho người dùng quyền giới hạn, có thể chấm
                dứt, không độc quyền để truy cập và sử dụng FinHub với mục đích
                đầu tư.
              </p>
              <p>
                Khi đăng ký tạo tài khoản trên FinHub, người dùng sẽ được yêu
                cầu tạo tài khoản và cung cấp cho FinHb một số thông tin nhất
                định như: ảnh chụp chứng minh nhân dân, địa chỉ email hợp lệ, số
                điện thoại của người dùng.
              </p>
              <p>
                Người dùng thừa nhận và đồng ý rằng một mình người dùng chịu
                trách nhiệm về nội dung, tính xác thực của bất kỳ thông tin cá
                nhân do người dùng cung cấp, đăng tải trên FinHub và bất kỳ hệ
                lụy nào phát sinh từ việc đăng tải này.
              </p>
              <p>
                FinHub có thể thu thập một số thông tin người dùng nhất định và
                có thể liên hệ với người dùng dịch vụ. FinHub được quyền tùy ý
                tuân theo các yêu cầu pháp lý, các yêu cầu từ cơ quan thi hành
                án hoặc các cơ quan quản lý, mặc dù sự tuân thủ này có thể bao
                gồm việc công bố một số thông tin người dùng nhất định.
              </p>
              <p>
                Tài khoản ngân hàng mà người dùng cung cấp cho FinHub là tài
                khoản mà người dùng sẽ nhận lại số tiền người dùng rút ra từ tài
                khoản của người dùng trên FinHub.
              </p>
            </div>

            <div>
              <p>
                <strong>IV. Quyền từ bỏ</strong>
              </p>
              <ol>
                <li>
                  FinHub không chịu bất kỳ trách nhiệm pháp lý đối với bất kỳ sự
                  mất mát thông tin xuất phát từ phía người dùng, gián đoạn dịch
                  vụ do sự cố bất khả kháng.
                </li>
                <li>
                  Người dùng thừa nhận và đồng ý rằng FinHub không cần thực hiện
                  các hợp đồng bằng giấy hay sử dụng chữ ký tươi để sử dụng các
                  dịch vụ trên FinHub.{" "}
                </li>
                <li>
                  Người dùng thừa nhận và đồng ý rằng tất cả các thao tác người
                  dùng thực hiện trên FinHub hay sử dụng các dịch vụ trên FinHub
                  đều thông qua tài khoản người dùng là thật sự của người dùng,
                  thay cho chữ ký tươi của người dùng và đều có tính chất pháp
                  lý.
                </li>
              </ol>
            </div>

            <div>
              <p>
                <strong>V. Chấm dứt hiệu lực</strong>
              </p>
              <p>
                Điều khoản này có đầy đủ hiệu lực và tác dụng ngay khi người
                dùng sử dụng FinHub.
              </p>
              <p>
                Khi người dùng vi phạm điều khoản này, hoặc nếu FinHub không thể
                xác minh hoặc chứng thực các thông tin người dùng mà người dùng
                gửi đến khi đăng ký tại FinHub, FinHub có quyền loại bỏ thông
                tin của người dùng và lập tức chấm dứt đăng ký và quyền truy cập
                của người dùng hoặc các dịch vụ khác được cung cấp bởi FinHub.
              </p>
            </div>
          </div>
        </Tab>
        <Tab eventKey="other" title="Điều khoản khác">
          <div style={{ textAlign: "justify" }}>
            <div>
              <p>
                <strong>Bản Công Bố Rủi Ro</strong>
              </p>
              <p>
                Khách hàng tham gia giao dịch các sản phẩm tài chính qua nền
                tảng FinHub của Công ty cổ phần Công nghệ tài chính FinHub
                (“Công ty”) được yêu cầu phải đọc, hiểu rõ và chấp nhận những ưu
                điểm của dịch vụ cũng như các rủi ro được nêu trong Bản công bố
                rủi ro (Bản CBRR) này.
              </p>
            </div>

            <div>
              <p>
                <strong>I. Mục đích</strong>
              </p>
              <p>
                Bản CBRR này được thiết lập nhằm thông báo tới Khách hàng những
                ưu điểm của giao dịch sản phẩm tài chính trực tuyến và các rủi
                ro mà Khách hàng có thể gặp phải trong quá trình giao dịch được
                Công ty nhận diện trước thời điểm Bản CBRR này được soạn thảo.
                Bản CBRR này sẽ được Công ty cập nhật khi có những rủi ro mới
                được nhận diện và Khách hàng được yêu cầu cập nhật những sửa đổi
                bổ sung ngay khi Công ty công bố trên nền tảng FinHub. Khách
                hàng chịu trách nhiệm với các giao dịch mà Khách hàng lựa chọn
                và thực hiện với Công ty qua nền tảng FinHub.
              </p>
              <ol>
                <li>
                  <strong>Ưu điểm dịch vụ</strong>
                  <ul>
                    <li>
                      Dịch vụ đa dạng và đồng bộ đáp ứng nhu cầu giao dịch trực
                      tuyến của Khách hàng.
                    </li>
                    <li>
                      Hệ thống giao dịch thuận tiện mọi lúc mọi nơi, giúp khách
                      hàng có thể tiết kiệm thời gian.
                    </li>
                    <li>
                      Hệ thống giao dịch với các thiết bị hiện đại và kỹ thuật
                      tiên tiến có thể phục vụ được số lượng lớn khách hàng.
                    </li>
                    <li>
                      Trang web được chứng thực bởi tổ chức có uy tín trên thế
                      giới với giao thức SSL, đảm bảo an toàn về dữ liệu truy
                      cập và dữ liệu trên đường truyền.
                    </li>
                    <li>
                      Các giao dịch trực tuyến được bảo mật bằng xác thực 2 lớp:
                      Email/Mật khẩu và OTP SMS.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>
                    Rủi ro Khách hàng có thể gặp phải trong quá trình thực hiện
                    giao dịch trực tuyến
                  </strong>
                  <ul>
                    <li>
                      Các lỗi kỹ thuật xảy ra do phát sinh từ lỗi hệ thống phần
                      cứng, phần mềm không hoạt động hoặc hoạt động không đúng
                      như thiết kế bởi các trường hợp bất khả kháng như: thiên
                      tai, động đất, lũ lụt, hỏa hoạn, chập điện, hư hỏng tự
                      nhiên…
                    </li>
                    <li>
                      Hệ thống có thể bị nhiễm virút, bị tin tặc tấn công hoặc
                      bị ảnh hưởng do các yếu tố nằm ngoài tầm kiểm soát của
                      Công ty gây ngưng trệ hoạt động trao đổi thông tin trong
                      hệ thống mạng, dẫn đến việc không thể xử lý hoặc thực hiện
                      giao dịch trực tuyến của khách hàng.
                    </li>
                    <li>
                      Các lệnh giao dịch có thể bị treo, bị ngừng, trì hoãn và
                      có lỗi dữ liệu do lỗi đường truyền internet của nhà cung
                      cấp dịch vụ kỹ thuật như: tắc nghẽn, quá tải đường truyền.
                    </li>
                    <li>
                      Việc nhận dạng Khách hàng tổ chức, các nhà đầu tư cá nhân
                      có thể bị nhầm, không chính xác do các thông tin nhận dạng
                      khách hàng như tên truy cập, mật khẩu truy cập, địa chỉ
                      thư điện tử, số điện thoại, số fax…bị chiếm đoạt bất hợp
                      pháp bởi bên thứ 3…
                    </li>
                    <li>
                      Những rủi ro có thể xảy ra do các phương thức xác thực đặt
                      lệnh mua bán cho nhà đầu tư.
                    </li>
                    <li>
                      Tốc độ truy cập hệ thống có thể bị ảnh hưởng bất lợi bởi
                      lưu lượng truy cập internet cao hoặc các yếu tố khác ngoài
                      tầm kiểm soát của Công ty.
                    </li>
                    <li>
                      Các thông tin về quỹ mở trích dẫn từ website của các công
                      ty quản lý quỹ có thể bị lỗi do các nguyên nhân khách
                      quan.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Miễn trừ trách nhiệm</strong>
                  <ul>
                    <strong>
                      Công ty không chịu trách nhiệm đối với bất kỳ sai sót hoặc
                      thiệt hại nào phát sinh do:
                    </strong>
                    <li>
                      Khách hàng cung cấp thông tin chậm trễ, thiếu sót hay
                      không chính xác dẫn đến không sử dụng được Dịch vụ Giao
                      dịch điện tử và/hoặc thực hiện Giao dịch điện tử.
                    </li>
                    <li>
                      Lỗi của Hệ thống hay bất kỳ phương tiện kỹ thuật nào liên
                      quan dẫn đến việc Hệ thống từ chối thực hiện Giao dịch
                      điện tử của Khách hàng.
                    </li>
                    <li>
                      Lỗi của bất kỳ bên thứ ba nào, bao gồm nhưng không giới
                      hạn những đối tác của Công ty trong việc cung cấp Dịch vụ
                      Giao dịch điện tử.
                    </li>
                    <li>
                      Công ty thực hiện chậm trễ hay không thể thực hiện được
                      trách nhiệm của mình theo đúng các điều khoản và quy định
                      sử dụng Dịch vụ Giao dịch điện tử này do có sự trục trặc
                      về máy móc, xử lý dữ liệu, thông tin viễn thông, thiên tai
                      hay bất kỳ sự việc nào ngoài sự kiểm soát hay do hậu quả
                      của sự gian lận, giả mạo của bất kỳ bên thứ ba.
                    </li>
                    <li>
                      Việc sử dụng Dịch vụ Giao dịch điện tử và/hoặc thực hiện
                      Giao dịch điện tử hoặc tiếp cận các thông tin mà Dịch vụ
                      Giao dịch điện tử cung ứng của những người được Khách hàng
                      ủy quyền.
                    </li>
                  </ul>
                </li>
                <p>
                  Công ty không chịu trách nhiệm nếu khách hàng để lộ mật khẩu
                  truy cập, hoặc làm thất lạc các thiết bị xác thực người dùng.
                  Trong trường hợp khách hàng phát hiện có người không được ủy
                  quyền sử dụng mật khẩu truy cập tài khoản và thực hiện đặt
                  lệnh, xin vui lòng thông báo ngay lập tức đến công ty để có
                  biện pháp xử ý kịp thời.
                </p>
                <p>
                  Các giao dịch điện tử do Khách hàng thực hiện thông qua FinHub
                  sẽ được xử lý và thực hiện một cách tự động ngay khi giao dịch
                  được truyền đến hệ thống của công ty. Do đó công ty không chịu
                  bất kỳ trách nhiệm nào đối với mọi thiệt hại của khách hàng
                  phát sinh từ các giao dịch của khách hàng được công ty cung
                  ứng.
                </p>
                <li>
                  <strong>Cam kết</strong>
                  <p>
                    Chúng tôi cam kết áp dụng các giải pháp tối ưu để mang lại
                    cho Quý khách hàng dịch vụ với chất lượng tốt nhất và giảm
                    thiểu rủi ro có thể gặp phải về kỹ thuật.
                  </p>
                  <p>
                    Trân trọng cảm ơn Quý khách hàng đã tin tưởng lựa chọn dịch
                    vụ giao dịch điện tử của Công ty cổ phần công nghệ tài chính
                    FinHub.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default TermsOfUse;
