import { icons } from "@/themes/images";
import React from "react";
import styles from "./style.module.css";
import { useParams } from "react-router-dom";

export default function ShareTools({post_id}: {post_id?: number}) {

  const {id} = useParams()
  const postUrl = encodeURIComponent(`https://finhub.com.vn/article/${id}`);
 
  const listSocial = [
    {
      name: "facebook",
      icon: icons.shareFacebook,
      link: `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`,
    },
    {
      name: "twitter",
      icon: icons.shareTwitter,
      link: `https://twitter.com/intent/tweet?url=${postUrl}`
    },
    {
      name: "linkedin",
      icon: icons.shareLinkedin,
      link:`https://www.linkedin.com/shareArticle?mini=true&url=${postUrl}`,
    },
    // {
    //   name: "instagram",
    //   icon: icons.shareInstagram,
    //   link: "https://www.instagram.com",
    // },
  ];
  return (
    <div className={styles.container}>
      <p>Chia sẻ qua:</p>
      <div className={styles.social_container}>
        {listSocial.map((item, index) => (
          <a key={index} href={item.link} target="_blank" rel="noreferrer">
            <img src={item.icon} alt={item.name} />
          </a>
        ))}
      </div>
    </div>
  );
}
