import React from "react";

import "./BoxTitlePage.css";

const BoxTitlePage = ({ title, subTitle }) => {
  return (
    <div className="boxTitle_page">
      <h2 className="display_large color_primary">{title}</h2>
      <p className="text_regular color_primary"> {subTitle}</p>
    </div>
  );
};

export default BoxTitlePage;
