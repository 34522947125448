import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./SideBar.css";
import { Button, Collapse } from "react-bootstrap";
function SideBar() {
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const newItems = [];
    for (let item of items) {
      if (currentPath.includes(item.path)) {
        const newItem = { ...item, active: true };
        newItems.push(newItem);
      } else {
        const newItem = { ...item, active: false };
        newItems.push(newItem);
      }
    }
    setItems(newItems);
  }, [currentPath]);

  const [items, setItems] = useState([
    {
      name: "Câu hỏi thường gặp",
      path: "/support/cau-hoi-thuong-gap",
      active: false,
    },
    {
      name: "Điều khoản sử dụng",
      path: "/support/dieu-khoan-dich-vu",
      active: false,
    },
  ]);

  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);

  const renderItem = items.map((item, index) => {
    return (
      <Link
        key={index}
        style={{ color: "inherit", textDecoration: "unset" }}
        to={item.path}
      >
        <div className={"side-item " + (item.active ? "active" : "")}>
          {item.name}
        </div>
      </Link>
    );
  });
  return (
    <>
      <div className="sidebar-container">
        <div>{renderItem}</div>
      </div>

      <div className="sidebar-container-mb">
        <Button
          className="mb-2 primary-color"
          onClick={handleToggle}
          aria-controls="collapse-sidebar"
          aria-expanded={open}
        >
          Menu
        </Button>
        <Collapse in={open}>
          <div id="collapse-sidebar">{renderItem}</div>
        </Collapse>
      </div>
    </>
  );
}

export default SideBar;
