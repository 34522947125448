import { getDetails } from "@/services/blog/blog";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useGetDetails = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);

  const getData = async (id) => {
    setLoading(true);
    const res = await getDetails(id);
    setLoading(false);
    setData(res?.data);
  };

  useEffect(() => {
    getData(params?.id);
  }, [params?.id]);

  return { data, loading };
};
