import React from "react";
// import "./Product.css";
import { useNavigate } from "react-router-dom";
import styles from "./KienThuc.module.css";

import BannerBackGround from "@/assets/images/background/kien-thuc.png";
const KienThuc = () => {
  const navigate = useNavigate();

  function navigateHandler(path) {
    navigate(`/kien-thuc/${path}`);
  }
  return (
    <>
      <div className={styles.container}>
        <div
          style={{
            backgroundImage: `url(${BannerBackGround})`,
          }}
        >
          <div className={styles.banner_container + " container"}>
            <div className="row gx-3">
              <div className={"col-xs-12 col-md-9"}>
                <div className={styles.left}>
                  <div className={styles.title}>
                    FinHub luôn đồng hành cùng bạn trên hành trình tài chính.
                  </div>
                  <div className={styles.text}>
                    Hãy yên tâm và bắt đầu hành trình của mình ngay hôm nay!
                  </div>
                </div>
              </div>
              <div className={"col-xs-12 col-md-3"}>
                <div className={styles.right}>
                  <img
                    src={
                      require("@/assets/images/icon/svg/kien-thuc-tai-chinh.svg")
                        .default
                    }
                  />
                  <div className={styles.title}>Kiến thức</div>
                  <div className={styles.text}>
                    Khám phá và mở ra cánh cửa tài chính cho tương lai.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-3 mx-auto">
              <div
                className="d-grid gap-2 product-card-container"
                onClick={() => navigateHandler("thuat-ngu")}
              >
                <div style={{ background: "#F5F5FE", borderRadius: "10px" }}>
                  <img
                    className="img-fluid w-100"
                    style={{ borderRadius: "10px" }}
                    src={require("@/assets/images/picture/png/product3.png")}
                  />
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <div className="product-card-tag primary-color">
                    Thông tin
                  </div>
                  <div className="product-card-tag primary-color">Web</div>
                  <div className="product-card-tag primary-color">Quỹ mở</div>
                </div>
                <div className="product-card-title text-center">Thuật ngữ</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KienThuc;
