import { getDM3 } from "@/services/blog/blog";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useGetDm3 = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);

  const queryParamsType = new URLSearchParams(location.search).get("type-dm3");

  const getData = async (id) => {
    setLoading(true);
    const res = await getDM3(id);
    setLoading(false);
    setData(res?.data);
  };

  useEffect(() => {
    getData(queryParamsType);
  }, [queryParamsType]);

  return { data, loading };
};
