import React from "react";

import Logo from "@/assets/images/logo/logo_footer_blog.jpg";
import Qr from "@/assets/images/picture/jpg/qr.jpg";
import Ch from "@/assets/images/picture/jpg/ch_play.jpg";
import App from "@/assets/images/picture/jpg/app_store.jpg";

import "./FooterBlog.css";
import { icons } from "@/utils/icons";
import { Link } from "react-router-dom";
import MobileAppStore from "@/constants/mobile-store";

const { EmailtSvg, PhoneSvg, AdressSvg, TwSvg, FbSvg, InSvg, GitSvg, LinSvg } =
  icons;

const footerBlog = [
  {
    title: "Đường dẫn",
    paths: [
      {
        label: "Về FinHub",
        path: "/about",
      },
      {
        label: "Sản phẩm",
        path: "/product",
      },
      {
        label: "Hệ thống FinHub",
        path: "/system-finhub",
      },
      {
        label: "Hỗ trợ khách hàng",
        path: "/support",
      },
    ],
  },
  {
    title: "Blog",
    paths: [
      {
        label: "Mới nhất",
        path: "/blog/latest",
      },
      {
        label: "Thông số tài chính",
        path: "/blog/Thông%20số%20tài%20chính?type=2",
      },
      {
        label: "Quản lý tài chính cá nhân",
        path: "/blog/Quản%20lý%20tài%20chính%20cá%20nhân?type=3",
      },
      {
        label: "Quản lý tài sản",
        path: "/blog/Quản%20lý%20tài%20sản?type=4",
      },
      {
        label: "Tin tức",
        path: "/blog/news",
      },
    ],
  },
  // {
  //   title: "Liên hệ",
  //   paths: [
  //     {
  //       label: "contact@finhub.com.vn",
  //       icon: <EmailtSvg />,
  //       path: "",
  //     },
  //     // {
  //     //   label: "+84 7108 1868",
  //     //   icon: <PhoneSvg />,
  //     //   path: "",
  //     // },
  //     {
  //       label:
  //         "Trung tâm Đổi mới sáng tạo Quốc gia, tòa nhà NIC, ngõ 7 Tôn Thất Thuyết, phường Dịch Vọng Hậu, quận Cầu Giấy, Hà Nội",
  //       icon: <AdressSvg />,
  //       path: "",
  //     },
  //   ],
  //   listIcon: [<TwSvg />, <InSvg />, <FbSvg />, <LinSvg />],
  // },
];

const FooterBlog = () => {
  return (
    <footer>
      <div className="footer_blog container">
        <div className="footer_blog_box_logo footerBlog_list_flex">
          <div className="footer_blog_logo">
            <img src={Logo} alt="logo_blog" />
          </div>
          <div>
            <p>We’re always in search for talented and motivated people.</p>
          </div>

          <div className="box">
            <div className="box_qr">
              <img src={Qr} alt="ar" />
            </div>

            <div className="box_store">
              <div>
                <a href={MobileAppStore.ANDROID} target="_blank">
                  <img src={Ch} alt="chplay" />
                </a>
              </div>

              <div>
                <a href={MobileAppStore.APPLE} target="_blank">
                  <img src={App} alt="appstore" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {footerBlog.map((item, i) => (
          <div
            key={i}
            className={`${i === 2 && "footerBlog_list"} footerBlog_list_flex`}
          >
            <p className="footer_blog_title color_primary">{item.title}</p>

            <div className="footer_blog_box">
              {item.paths.map((itePath, itePathI) => (
                <div key={itePathI} className="itePath_box">
                  {!!itePath.icon && itePath.icon}
                  <Link to={itePath.path} className="itePath_title">
                    {itePath.label}
                  </Link>
                </div>
              ))}
            </div>

            <div className="list_icon">
              {!!item.listIcon &&
                item.listIcon.map((iconPath, iconPathi) => (
                  <span key={iconPathi}>{iconPath}</span>
                ))}
            </div>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default FooterBlog;
