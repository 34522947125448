import axios from "axios";

const axiCms = axios.create({
  baseURL: "https://cms.finhub.com.vn",
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiCms;
