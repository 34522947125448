import React from "react";
import RelatedArticlesList from "../RelatedArticlesList";
import styles from "./style.module.css";
import { useParams } from "react-router-dom";
import { useGetRelatedArticles } from "@/services/new/apiMutations/article.mutation";


const RelatedArticlesComponent = React.memo(function RelatedArticlesComponent() {
  return (
    <div className={styles.container}>
      {/* Header */}
      <div className={styles.header}>
        <p className={styles.title}>Tin tức liên quan</p>
      </div>
      {/* Body */}
      <RelatedArticlesList />
    </div>
  );
});

export default RelatedArticlesComponent;
