import React from "react";
// import "./Product.css";
import { useNavigate } from "react-router-dom";
import styles from "./CongCu.module.css";
import BannerBackGround from "@/assets/images/background/kien-thuc.png";
const CongCu = () => {
  const navigate = useNavigate();

  function navigateHandler(path) {
    navigate(`/cong-cu/${path}`);
  }
  return (
    <>
      <div className={styles.container}>
        <div
          style={{
            backgroundImage: `url(${BannerBackGround})`,
          }}
        >
          <div className={styles.banner_container + " container"}>
            <div className="row gx-3">
              <div className={"col-xs-12 col-md-9"}>
                <div className={styles.left}>
                  <div className={styles.title}>
                    FinHub luôn đồng hành cùng bạn trên hành trình tài chính.
                  </div>
                  <div className={styles.text}>
                    Hãy yên tâm và bắt đầu hành trình tài chính của mình ngay
                    hôm nay!
                  </div>
                </div>
              </div>
              <div className={"col-xs-12 col-md-3"}>
                <div className={styles.right}>
                  <img
                    src={
                      require("@/assets/images/icon/svg/kien-thuc-tai-chinh.svg")
                        .default
                    }
                  />
                  <div className={styles.title}>Công cụ hỗ trợ quyết định tài chính</div>
                  <div className={styles.text}>
                    Công cụ đồng hành, quyết định hiệu quả
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <img
            className="img-fluid"
            src={require("@/assets/images/picture/jpg/product-banner.jpg")}
            alt="product"
          />
        </div> */}
        <div className="container my-5">
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-3 mx-auto">
              <div
                className="d-grid gap-2 product-card-container"
                onClick={() => navigateHandler("robo-advisor")}
              >
                <div style={{ background: "#F5F5FE", borderRadius: "10px" }}>
                  <img
                    className="img-fluid w-100"
                    src={require("@/assets/images/picture/png/product2.png")}
                  />
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <div className="product-card-tag primary-color">Công cụ</div>
                  <div className="product-card-tag primary-color">Ứng dụng</div>
                  <div className="product-card-tag primary-color">Đầu tư</div>
                </div>
                <div className="product-card-title text-center">
                  Robo Advisor
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6 col-lg-3 mx-auto">
              <div
                className="d-grid gap-2 product-card-container"
                onClick={() => navigateHandler("mo-phong-dau-tu-quy-mo")}
              >
                <div style={{ background: "#F5F5FE", borderRadius: "10px" }}>
                  <img
                    className="img-fluid w-100"
                    src={require("@/assets/images/picture/png/product4.png")}
                  />
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <div className="product-card-tag primary-color">Mở rộng</div>
                  <div className="product-card-tag primary-color">Ứng dụng</div>
                  <div className="product-card-tag primary-color">
                    Thông tin
                  </div>
                </div>
                <div className="product-card-title text-center">
                  Mô phỏng đầu tư
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CongCu;
