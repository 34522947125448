import React from "react";
import "./CardNew.css";

import { Link } from "react-router-dom";
import { icons } from "@/utils/icons";

import moment from "moment";

const { CalendarSvg, AuthorSvg, ArrowRightSvg } = icons;

const CardNew = ({ thumbnail, title, description, author, createdAt, id }) => {
  return (
    <Link to={`/blog/details/${id}`} className={` card_new`} title={title}>
      {/* Thumnail */}
      <div className={`card_new_thumbnail`}>
        <img src={thumbnail} alt={title} />
      </div>

      {/* Infor */}

      <div className={`card_new_infor `}>
        {/* Author */}
        <div className="card_new_author">
          <div>
            <AuthorSvg />
            <p className="color_gray3 text_regular">{author}</p>
          </div>
          <div>
            <CalendarSvg />
            <p className="color_gray3 text_regular">
              {moment(createdAt).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>

        {/* Title */}
        <div className="card_new_title">
          <h2 className="headline_small color_primary">{title}</h2>
        </div>

        {/* Description */}
        <div className="card_new_description">
          <p className="text_regular color_primary">{description}</p>
        </div>

        <div className="card_new_xemchitiet">
          <p>Xem chi tiết</p>
          <ArrowRightSvg />
        </div>
      </div>
    </Link>
  );
};

export default CardNew;
