import React from "react";
import styles from "./style.module.css";
import { ArticalCardType, ArticleInterface } from "@/interfaces/Article";
import { useNavigate } from "react-router-dom";
import { useArticleState } from "@/stores/useArticleState";
interface RelatedArticleCardProps {
  article: ArticleInterface;
}

export default function RelatedArticleCard({
  article,
}: RelatedArticleCardProps) {
  const navigate = useNavigate();
  const { categoryList } = useArticleState();
  function handleClick() {
    navigate(`/article/${article.id}`);
  }
  return (
    <div onClick={handleClick} className={styles.container + " container"}>
      <div className="row">
        <div className="col-xs-12 col-md-6 col-xl-5 px-0">
          {article.thumbnail ? (
            <img
              className={styles.image}
              src={article.thumbnail}
              alt="article"
            />
          ) : (
            <img
              className={styles.image}
              src="https://e0.pxfuel.com/wallpapers/963/105/desktop-wallpaper-finance-50-financial.jpg"
              alt="article"
            />
          )}
        </div>
        <div className="col-xs-12 col-md-6 col-xl-7 px-0">
          <div className={styles.right_container}>
            <div>
              <p className={styles.title}>{article.title}</p>
            </div>

            <footer className={styles.footer}>
              <div className={styles.group}>
                <p className={styles.footer_text}>
                  {article.category_id &&
                    categoryList.find(
                      (category) => category.id === article.category_id
                    )?.name}
                </p>
              </div>
              |
              <div className={styles.group}>
                <p className={styles.footer_text}>
                  {new Date(article.created_date).toLocaleDateString()}
                </p>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}
