import React from "react";
import BoxTitlePage from "../_components/BoxTitlePage/BoxTitlePage";
import Layout from "../_components/Layout/Layout";
import GroupBoxNavigateBlack from "../_components/GroupBoxNavigateBlack/GroupBoxNavigateBlack";
import { useGetDm2 } from "@/hook/blog/useGetDm2";
import Ricktext from "../_components/Ricktext/Ricktext";
import Loading from "../_components/Loading/Loading";
import Card from "../_components/Card/Card";

import Banner from "@/assets/images/picture/jpg/banner_dm_2.jpg";

import "./DM.css";

const DM2 = () => {
  const { data, loading } = useGetDm2();

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Layout>
        <div className="">
          <BoxTitlePage
            title={data?.attributes?.name}
            subTitle={data?.attributes?.short_description}
          />
          <GroupBoxNavigateBlack list={data?.attributes?.dm_cap_3s?.data} />

          <div style={{ margin: "60px 0" }}>
            <Ricktext content={data?.attributes?.detail} />
          </div>

          <div className="list_vertical dm_2_list">
            {data?.attributes?.bai_viets?.data?.map((item) => (
              <Card
                key={item?.id}
                thumbnail={item?.attributes?.thumbnail?.data?.attributes?.url}
                category={
                  item?.attributes?.category_name?.data?.[0]?.attributes?.name
                }
                author={item?.attributes?.author}
                createdAt={item?.attributes?.createdAt}
                description={item?.attributes?.short_description}
                title={item?.attributes?.title}
                isSmall={true}
                id={item?.id}
              />
            ))}
          </div>
        </div>
      </Layout>
      <div className="dm_2_banner">
        <div
          style={{
            backgroundImage: `url(${Banner})`,
          }}
        />
      </div>
    </div>
  );
};

export default DM2;
