import React from "react";

import styles from "./style.module.css";

const Ricktext = ({ content }: any) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: content,
      }}
      className={styles.rich_text}
    ></div>
  );
};

export default Ricktext;
