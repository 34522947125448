import { Outlet, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import SideBar from "@/components/About/SideBar/SideBar";
import BreadcrumbComponent from "@/components/Breadcrumb/Breadcrumb";
import useWindowSize from "@/hook/useWindowSize";
const AboutLayout = () => {
  const { width } = useWindowSize();
  const breadcrumbItems = [
    { text: "Trang chủ", href: "/" },
    { text: "Về FinHub", href: "/about" },
  ];

  // Determine if the screen is large (lg) or larger based on the width
  const isLargeScreen = width < 992; // Bootstrap's lg breakpoint


  return (
    <>
      <Container fluid={isLargeScreen} className="mt-4 mb-5">
        <Row>
          <Col xs={12} md={3}>
            <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />
            <SideBar />
          </Col>
          <Col xs={12} md={9}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutLayout;
