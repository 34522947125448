import "./FormRegister.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState, useRef, useCallback } from "react";
import ApiServices from "@/services/api.ts";
import { v4 as uuidv4 } from 'uuid';

const MAX_FILE_SIZE = 5000000; // 5MB
const apiServices = new ApiServices();

function FormRegister({jobDetail}) {
  const fileInputRef = useRef(null);
  const [state, setState] = useState({
    isLoading: false,
    formData: {
      fullname: "",
      email: "",
      phone: "",
      resume: null,
    }
  });

  const handleInputChange = useCallback((event) => {
    const { name, value, files } = event.target;
    setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: files && files[0] ? files[0] : value
      }
    }));
  }, []);

  const getPresignedUrl = async (file, key) => {
    const payload = {
      Key: key,
      ContentType: file.type,
    };
    return apiServices.executePostRequest(`/job/get_pre_signed_url_put`, payload);
  }

  const uploadFileToS3 = async (file, url) => {
    try {
      await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    if (state.formData?.resume?.size > MAX_FILE_SIZE) {
      alert("Kích thước file phải thấp hơn 5MB");
      return;
    }

    setState(prevState => ({ ...prevState, isLoading: true }));
    const data = new FormData();
    data.append("fullname", state.formData.fullname);
    data.append("email", state.formData.email);
    data.append("phone", state.formData.phone);
    const payload = {
      fullname: state.formData.fullname,
      email: state.formData.email,
      phone: state.formData.phone,
    }
    if (state.formData.resume) {
      const key = uuidv4()+'-'+state.formData.resume.name;
      const res = await getPresignedUrl(state.formData.resume, key);
      await uploadFileToS3(state.formData.resume, res.data.url);
      const parsedUrl = new URL(res.data.url);
      // data.append("resume_url", parsedUrl.origin + parsedUrl.pathname);
      payload.resume_url = parsedUrl.origin + parsedUrl.pathname;

    } else {
      // data.append("resume_url", "");
      payload.resume_url = "";
    }
    try {
      const response = await apiServices.executePostRequest(`/job/apply/${jobDetail.id}`, payload);
      if (response === 'NETWORK_ERROR' || !response) {
        throw new Error('HTTP error ' + response.status);
      }
      alert("Form submitted successfully");
      setState({ isLoading: false, formData: { fullname: "", email: "", phone: "", resume: null } });
      fileInputRef.current.value = '';
    } catch (error) {
      setState(prevState => ({ ...prevState, isLoading: false }));
      alert("Error submitting form");
    }
  }, [state.formData]);

  // Rest of the component remains the same...

  return (
    <div>
      <Form onSubmit={handleSubmit} className="about-form-container">
        <div
          style={{ fontSize: "22px", fontWeight: 500 }}
          className="primary-color"
        >
          Gửi đơn đăng ký
        </div>
        <Form.Group className="mb-3">
          <Form.Label className="about-form-label" htmlFor="fullname">
            Họ và tên <span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            id="fullname"
            autoComplete="true"
            className="about-form-input"
            type="text"
            name="fullname"
            placeholder="Nhập họ và tên"
            required
            onChange={handleInputChange}
            value={state.formData.fullname}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="about-form-label" htmlFor="email">
            Email <span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            autoComplete="true"
            className="about-form-input"
            type="email"
            name="email"
            id="email"
            placeholder="Nhập email"
            required
            onChange={handleInputChange}
            value={state.formData.email}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="about-form-label" htmlFor="phone">
            Điện thoại <span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            autoComplete="true"
            className="about-form-input"
            type="tel"
            name="phone"
            id="phone"
            placeholder="Nhập số điện thoại"
            required
            onChange={handleInputChange}
            value={state.formData.phone}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="fileInput" className="about-form-label">
            Đính kèm sơ yếu lí lịch
          </Form.Label>
          <Form.Control
            id="fileInput"
            accept=".pdf, .doc"
            className="about-form-input"
            type="file"
            onChange={handleInputChange}
            name="resume"
            
            ref={fileInputRef}
          />
          <Form.Text className="text-muted">
            {"Chúng tôi chấp nhận tệp kích thước <5MB"}
          </Form.Text>
        </Form.Group>

        <Button disabled={state.isLoading} className="about-form-submit" variant="primary" type="submit">
          Gửi yêu cầu
        </Button>
      </Form>
    </div>
  );
}

export default FormRegister;
