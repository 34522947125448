import React, { useEffect } from "react";
import FilterTopicComponent from "./components/FilterTopic";
import ArticleListComponent from "./components/ArticleList";
import LoadMore from "./components/LoadMore";
import styles from "./style.module.css";
import {
  getAllArticle,
  getArticleByCategory,
} from "@/services/new/apiFunctions/article.api";
import { useArticleState } from "@/stores/useArticleState";

export default function JournalismCommunication() {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [selectedTopic, setSelectedTopic] = React.useState<number | null>(null);
  const { setArticleList, setMaxPage, articleList, maxPage } = useArticleState();
  function onLoadMore() {
    console.log("Load more");
    setPageNumber(pageNumber + 1);
  }

  async function getAllArticlesHandler(pageNumber: number) {
    try {
      const { data } = await getAllArticle(pageNumber);
      setArticleList([...articleList, ...data.data.thread]);
      setMaxPage(data.data.totalPage);
    } catch (error) {
      console.log(error);
    }
  }

  async function getArticleByCategoryHandler(categoryId: number) {
    try {
      const { data } = await getArticleByCategory(pageNumber, categoryId);
      setArticleList([...articleList, ...data.data.thread]);
      setMaxPage(data.data?.totalPage);
    } catch (error) {
      console.log(error);
    }
  }

  async function selectFilterTopicHandler(id: number) {
    // reset page number
    setPageNumber(1);
    setArticleList([]);
    if (selectedTopic === id) {
      setSelectedTopic(null);
      return;
    }

    setSelectedTopic(id);
  }

  useEffect(() => {
    if (selectedTopic === null) {
      getAllArticlesHandler(pageNumber);
    } else {
      getArticleByCategoryHandler(selectedTopic);
    }
  }, [pageNumber, selectedTopic]);

  return (
    <div className={styles.container}>
      {/* Header */}
      <FilterTopicComponent
        selectedTopic={selectedTopic}
        onSelect={(category_id) => selectFilterTopicHandler(category_id)}
      />

      {/* Body */}
      <ArticleListComponent />

      {/* Footer */}
      <div className={styles.footer}>
        {
          maxPage > pageNumber && (
            <LoadMore onLoadMore={onLoadMore} />
          )
        }
      </div>
    </div>
  );
}