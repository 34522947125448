import BoxDownload from "@/components/BoxDownload/BoxDownload";
import RegisterBox from "@/components/RegisterBox/RegisterBox";
import React from "react";

const BoxRightRegister = () => {
  return (
    <div className="BoxRightRegister">
      <RegisterBox />
      <BoxDownload />
    </div>
  );
};

export default BoxRightRegister;
