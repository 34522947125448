import React, { ReactNode } from "react";
import styles from "./style.module.css";
interface ZoomOnHoverProps {
  children: ReactNode;
}
const WrapperZoomOnHover: React.FC<ZoomOnHoverProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default WrapperZoomOnHover;
