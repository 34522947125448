import React from "react";
import "./Product.css";
import { useNavigate } from "react-router-dom";
const Product = () => {
  const navigate = useNavigate();

  function navigateHandler(path) {
    navigate(`/product/${path}`);
  }
  return (
    <>
      <div>
        <div>
          <img
            className="img-fluid"
            src={require("@/assets/images/picture/jpg/product-banner.jpg")}
            alt="product"
          />
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-3 mx-auto">
              <div
                className="d-grid gap-2 product-card-container"
                onClick={() => navigateHandler("chung-chi-quy-mo")}
              >
                <div style={{ background: "#F5F5FE", borderRadius: "10px" }}>
                  <img
                    className="img-fluid w-100"
                    src={require("@/assets/images/picture/png/product1.png")}
                    alt="prpduct-item"
                  />
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <div className="product-card-tag primary-color">Quỹ mở</div>
                  <div className="product-card-tag primary-color">Ứng dụng</div>
                  <div className="product-card-tag primary-color">
                    Giao dịch
                  </div>
                </div>
                <div className="product-card-title text-center">
                  Chứng chỉ quỹ mở
                </div>
              </div>
            </div>

            {/* <div className="col-xs-12 col-md-6 col-lg-3">
              <div
                className="d-grid gap-2 product-card-container"
                onClick={() => navigateHandler("robo-advisor")}
              >
                <div style={{ background: "#F5F5FE", borderRadius: "10px" }}>
                  <img
                    className="img-fluid w-100"
                    src={require("@/assets/images/picture/png/product2.png")}
                  />
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <div className="product-card-tag primary-color">Công cụ</div>
                  <div className="product-card-tag primary-color">Ứng dụng</div>
                  <div className="product-card-tag primary-color">Đầu tư</div>
                </div>
                <div className="product-card-title text-center">
                  Robo Advisor
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6 col-lg-3">
              <div
                className="d-grid gap-2 product-card-container"
                onClick={() => navigateHandler("thuat-ngu")}
              >
                <div style={{ background: "#F5F5FE", borderRadius: "10px" }}>
                  <img
                    className="img-fluid w-100"
                    style={{ borderRadius: "10px" }}
                    src={require("@/assets/images/picture/png/product3.png")}
                  />
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <div className="product-card-tag primary-color">
                    Thông tin
                  </div>
                  <div className="product-card-tag primary-color">Web</div>
                  <div className="product-card-tag primary-color">Quỹ mở</div>
                </div>
                <div className="product-card-title text-center">Thuật ngữ</div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6 col-lg-3">
              <div
                className="d-grid gap-2 product-card-container"
                onClick={() => navigateHandler("blog")}
              >
                <div style={{ background: "#F5F5FE", borderRadius: "10px" }}>
                  <img
                    className="img-fluid w-100"
                    src={require("@/assets/images/picture/png/product4.png")}
                  />
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <div className="product-card-tag primary-color">Mở rộng</div>
                  <div className="product-card-tag primary-color">Ứng dụng</div>
                  <div className="product-card-tag primary-color">
                    Thông tin
                  </div>
                </div>
                <div className="product-card-title text-center">Blog</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
