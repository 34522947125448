/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./HeaderBlog.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LogoImage from "@/assets/images/logo/logo_footer_blog.jpg";
import { Link, useLocation } from "react-router-dom";
import NavBlog from "./NavBlog";

const HeaderBlog = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [links, setLinks] = useState({
    home: {
      path: "/",
      active: false,
    },
    about: {
      path: "/about",
      active: false,
      subMenu: [
        {
          name: "Slogan",
          path: "/about/slogan",
        },
        {
          name: "Giới thiệu",
          path: "/about/gioi-thieu",
        },
        {
          name: "Đội ngũ quản lý",
          path: "/about/doi-ngu-quan-ly",
        },
        {
          name: "Giá trị cốt lõi",
          path: "/about/gia-tri-cot-loi",
        },
        {
          name: "Sản phẩm",
          path: "/about/san-pham",
        },
        // {
        //   name: "Đối tác & Đơn vị hỗ trợ",
        //   path: "/about/doi-tac-va-don-vi-ho-tro",
        // },
        {
          name: "Cơ hội nghề nghiệp",
          path: "/about/co-hoi-nghe-nghiep",
        },
      ],
    },
    product: {
      path: "/product",
      active: false,
      subMenu: [
        {
          title: "Kiến thức",
          link: '/kien-thuc',
          child: [
            {
              name: "Thuật ngữ",
              path: "/kien-thuc/thuat-ngu",
            },
          ],
        },
        {
          title: "Chứng chỉ quỹ mở",
          link: '/product/chung-chi-quy-mo',
          child: [
        
          ],
        },
        {
          title: "Công cụ",
          link: '/cong-cu',
          child: [
            {
              name: "Robo Advisor",
              path: "/cong-cu/robo-advisor",
            },
            {
              name: "Mô phỏng đầu tư",
              path: "/cong-cu/mo-phong-dau-tu-quy-mo",
            },
            
          ],
        },
      ],
    },
    system: {
      path: "/system-finhub",
      active: false,
    },
    support: {
      path: "/support",
      active: false,
    },
    blog: {
      path: "/blog",
      active: false,
    },
  });
  useEffect(() => {
    const newLinks = { ...links };
    for (let item of Object.keys(newLinks)) {
      // if (links[item].path === currentPath) {
      if (currentPath.includes(links[item].path)) {
        console.log(item);
        links[item].active = true;
      } else {
        links[item].active = false;
      }
    }
    setLinks(newLinks);
  }, [currentPath]);
  return (
    <header>
      <div
        style={{
          boxShadow:
            "0px 2px 4px 0px rgba(17, 17, 26, 0.05), 0px 4px 16px 0px rgba(17, 17, 26, 0.05)",
          flexShrink: 0,
        }}
      >
        <div className="container ">
          <Navbar expand="lg" className="header_container">
            <Container fluid>
              <Navbar.Brand as={Link} to={"/"} className="mt-0">
                <img
                  width={175}
                  height={59}
                  src={LogoImage}
                  alt="finhub logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="mx-auto my-2 my-lg-0 header_content"
                  navbarScroll
                >
                  <NavDropdown
                    title="Về FinHub"
                    id="navbarScrollingDropdown"
                    className={
                      links.about.active ? "header-item-active mt-0" : "mt-0"
                    }
                  >
                    {links.about.subMenu.map((item, index) => {
                      return (
                        <NavDropdown.Item
                          as={Link}
                          to={item.path}
                          style={{
                            color: "#21233D",
                            fontSize: '17px',
                            fontWeight: 500,
                            textDecoration: "none",
                          }}
                        >
                          {item.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>

                  <NavDropdown
                    title="Sản phẩm"
                    id="navbarScrollingDropdown"
                    className={links.product.active ? "header-item-active" : ""}
                  >
                    {links.product.subMenu.map((item, index) => {
                      return (
                        <>
                          <NavDropdown.Item as={Link} to={item.link}>
                            <div className="text-header">{item.title}</div>
                          </NavDropdown.Item>

                          <NavDropdown.Divider />

                          {item.child.map((childItem, childIndex) => {
                            return (
                              <NavDropdown.Item
                                key={childIndex}
                                as={Link}
                                to={childItem.path}
                                style={{
                                  color: "#21233D",
                                  fontWeight: 500,
                                  textDecoration: "none",
                                }}
                              >
                                <div className="d-flex gap-2  align-items-center">
                                  <img
                                    src={
                                      require("@/assets/images/icon/svg/arrow-left.svg")
                                        .default
                                    }
                                  />
                                  <span>{childItem.name}</span>
                                </div>
                              </NavDropdown.Item>
                            );
                          })}
                        </>
                      );
                    })}
                  </NavDropdown>

                  <Nav.Link
                    as={Link}
                    to={"/system-finhub"}
                    style={{
                      color: "#21233D",
                      fontWeight: 500,
                      textDecoration: "none",
                    }}
                    className={links.system.active ? "header-item-active" : ""}
                  >
                    Hệ thống FinHub
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to={"/support"}
                    style={{
                      color: "#21233D",
                      fontWeight: 500,
                      textDecoration: "none",
                    }}
                    className={links.support.active ? "header-item-active" : ""}
                  >
                    Hỗ trợ khách hàng
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to={"/blog"}
                    style={{
                      color: "#21233D",
                      fontWeight: 500,
                      textDecoration: "none",
                    }}
                    className={links.blog.active ? "header-item-active" : ""}
                  >
                    Blog
                  </Nav.Link>
                </Nav>
                {/* <NavDropdown
                title={<img width={37} src={FlagVN} alt="vietnam flag" />}
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item href="#action3">VietNam</NavDropdown.Item>
              </NavDropdown> */}
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
      <div className="container">
        <NavBlog />
      </div>
    </header>
  );
};

export default HeaderBlog;
