import "./CareerOpportunities.css";
import SearchPositionJob from "@/components/About/SearchPositionJob/SearchPositionJob";
import JobDetail from "@/components/About/JobDetail/JobDetail";
import { useState } from "react";
function CareerOpportunities() {
  const [jobDetail, setJobDetail] = useState();
  function updateJobDetail(data) {
    setJobDetail(data);
  }
  return (
    <>
      {jobDetail ? (
        <JobDetail jobDetail={jobDetail} updateJobDetail={updateJobDetail} />
      ) : (
        <div className="d-grid gap-3">
          <div>
            <div className="about-title primary-color">Cơ hội nghề nghiệp</div>
          </div>
          <div>
            <p className="about-sub-title">
              FinHub - Mở cửa cho sự phát triển, Nơi cơ hội bắt đầu
            </p>
          </div>
          <div>
            <p>
              Chúng tôi luôn mong muốn mở rộng đội ngũ và tìm kiếm những cá nhân
              năng động, sáng tạo cùng gia nhập FinHub. Với môi trường làm việc
              tích cực và chuyên nghiệp, chúng tôi tin rằng sự phát triển cá
              nhân và thành công của đội ngũ sẽ được thúc đẩy. Đồng hành cùng
              FinHub định hình tương lai tài chính!
            </p>
          </div>

          <div>
            <img
              className="img-fluid"
              src={require("@/assets/images/picture/jpg/about-career-opportunities.jpg")}
            />
          </div>
          <SearchPositionJob updateJobDetail={updateJobDetail} />
        </div>
      )}
    </>
  );
}

export default CareerOpportunities;
