import React from "react";
import "./OutstandingBox.css";
import TitleBorderGradient from "../TitleBorderGradient/TitleBorderGradient";
import Card from "../Card/Card";
import { useGetAllPost } from "@/hook/blog/useGetAllPost";

const OutstandingBox = ({ list }) => {
  const { data } = useGetAllPost();

  return (
    <div className="outstanding_box">
      {/* Title */}
      <TitleBorderGradient
        title="Bài viết nổi bật"
        className="title_large color_primary"
      />

      {/* List */}

      <div className="outstanding_box_list layout_box_bottom">
        {data?.slice(1, 5)?.map((item, i) => (
          <Card
            key={item?.id}
            thumbnail={item?.attributes?.thumbnail?.data?.attributes?.url}
            category={item?.attributes?.sub_category?.data?.attributes?.name}
            author={item?.attributes?.author}
            createdAt={item?.attributes?.createdAt}
            description={item?.attributes?.short_description}
            title={item?.attributes?.title}
            isSmall={true}
            id={item?.id}
          />
        ))}
      </div>
    </div>
  );
};

export default OutstandingBox;
