import React from "react";

import "./GroupBoxNavigateBlack.css";
import { useNavigate } from "react-router-dom";
import { useGetPathname } from "@/utils/getPathname";

const GroupBoxNavigateBlack = ({ list = [] }) => {
  const { dm1, queryDM1, dm2, queryDM2 } = useGetPathname();

  const navigate = useNavigate();

  const navigateToDM3 = (dm3, id) => {
    navigate(
      `/blog/${dm1}/${dm2}/${dm3}?type=${queryDM1}&type-dm2=${queryDM2}&type-dm3=${id}`
    );
  };

  return (
    <div className="group_box_navigate_black">
      {list?.map((item, i) => (
        <div
          key={i}
          className="group_box_navigate_black_item title_large color_primary text_center bg_primary color_white"
          onClick={() => navigateToDM3(item?.attributes?.name, item?.id)}
        >
          {item?.attributes?.name}
        </div>
      ))}
    </div>
  );
};

export default GroupBoxNavigateBlack;
