import "./CoreValues.css";
function CoreValues() {
  return (
    <>
      <div>
        <div>
          <p className="about-title primary-color">Giá trị cốt lõi</p>
        </div>
        <div>
          <p>
          Chúng tôi chia sẻ và duy trì 5 giá trị này bằng mọi cách để chúng tôi luôn đi đúng hướng:
          </p>
        </div>

        <div>
          <img
            className="img-fluid"
            src={require("@/assets/images/picture/png/core-value.png")}
          />
        </div>
      </div>
    </>
  );
}

export default CoreValues;
