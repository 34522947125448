import "./ManagementTeam.css";
import styles from './ManagementTeam.module.css'

import Avatar1 from "@/assets/images/picture/jpg/about-management-team1.jpg";
import Avatar2 from "@/assets/images/picture/jpg/about-management-team2.jpg";
import Avatar3 from "@/assets/images/picture/jpg/about-management-team3.jpg";
import Avatar4 from "@/assets/images/picture/jpg/about-management-team4.jpg";
function ManagementTeam() {
  const teamList = [
    {
      name: "Nhàn Nguyễn",
      role: "CEO",
      male: "Bà",
      description: [
        "Bà có 13 năm kinh nghiệm trong lĩnh vực tài chính.",
        "Bà có bằng cử nhân tại Đại học Quốc gia Singapore (NUS) với 2 chuyên ngành Tài chính và Marketing.",
        "Bà có bằng thạc sĩ quản trị kinh doanh tại Đại học Quốc tế Nhật Bản.",
        "Bà đã hoàn thành các chứng chỉ chuyên môn tại Ủy ban Chứng khoán Nhà nước.",
        "Trước khi thành lập FinHub, bà Nhàn Nguyễn từng công tác tại Ngân hàng Techcombank, Vietcombank, ANZ Bank.",
      ],
      avatarUrl: Avatar1,
    },
    {
      name: "Anh Phạm",
      role: "Product Manager",
      male: "Ông",
      description: [
        "Ông có hơn 14 năm kinh nghiệm trong lĩnh vực công nghệ phần mềm.",
        "Tốt nghiệp loại giỏi cử nhân công nghệ thông tin tại Đại học Bách khoa Hà Nội và được học bổng toàn phần học thạc sĩ Ms.CE tại Đại học Công nghệ Delft (Hà Lan).",
        "Ông có bằng thạc sĩ quản trị kinh doanh tại Đại học Urbana-Champaign.",
        "Trước khi thành lập FinHub, Ông Anh Phạm từng đảm nhận vị trí Head of Delivery của FPT Software (Hàn Quốc), quản lý 600 nhân viên.",
      ],
      avatarUrl: Avatar2,
    },
    {
      name: "Lộc Nguyễn",
      role: "Product Manager",
      male: "Ông",
      description: [
        "Ông có 11 năm kinh nghiệm trong lĩnh vực tư vấn tài chính.",
        "Ông có bằng cử nhân tài chính tại Đại học Northrumbia (Anh Quốc) và thạc sĩ công nghệ thông tin ICT tại Đại học De Portiers.",
        "Ông cũng có chứng chỉ CFA Level 3.",
        "Trước khi thành lập FinHub, ông từng làm tư vấn tài chính tại Deloitte và chuyên gia phát triển sản phẩm trái phiếu tại Công ty chứng khoán Techcom Securities.",
      ],
      avatarUrl: Avatar3,
    },
    {
      name: "Long Nguyễn",
      role: "Technical Lead",
      male: "Ông",
      description: [
        "Ông Long Nguyễn có hơn 10 năm kinh nghiệm trong lĩnh vực công nghệ phần mềm.",
        "Ông tốt nghiệp đại học Northern State University (Hoa Kỳ) và từng công tác tại FPT Software và Palantir Technologies.",
      ],
      avatarUrl: Avatar4,
    },
  ];
  return (
    <>
      <div>
        <div>
          <p className="about-title primary-color">Đội ngũ quản lý</p>
        </div>

        <div className="container-fluid p-0">
          <div className="row introduct-card-container">
            {teamList.map((item, index) => {
              return (
                <div key={index} className="card mb-3" style={{ border: 0 }}>
                  <div className="row g-0">
                    <div className="col-md-3 management-team-image">
                      <img
                        src={item.avatarUrl}
                        className="img-fluid rounded-start"
                        alt="..."
                      />
                    </div>
                    <div className="col-md-9">
                      <div className="card-body">
                        <p style={{ fontSize: "20px" }}>
                          {item.male}{" "}
                          <span style={{ fontSize: "22px", fontWeight: 500 }}>
                            {item.name}
                          </span>
                        </p>
                        <p
                          className="primary-color"
                          style={{ fontWeight: 500 }}
                        >
                          {item.role}{" "}
                        </p>
                        <ul>
                          {item.description.map((desc, indexDesc) => {
                            return <li className={styles.li} key={indexDesc}>{desc}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagementTeam;
