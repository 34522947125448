import React from "react";
import "./BoxDownload.css";

import Chplay from "@/assets/images/picture/jpg/ch_play.jpg";
import Appstore from "@/assets/images/picture/jpg/app_store.jpg";
import MobileAppStore from "@/constants/mobile-store";

const BoxDownload = () => {
  return (
    <div className="box_download bg_white layout_box_bottom">
      <div className="bg_white box_download_top">
        <p className="headline_small color_primary text_center">
          Tải ứng dụng FinHub ngay!
        </p>
      </div>

      <div className="box_download_bottom bg_gradient_blue100">
        <p className="text_regular text_center">
          Để cập nhật nhanh chóng và đầy đủ nhất các bài viết trên FinHub Blog
        </p>

        <div className="box_download_btn_group">
          <button>
            <a
              href={MobileAppStore.ANDROID}
              target="_blank"
            >
              <img src={Chplay} alt="Chplay" />
            </a>
          </button>
          <button>
          <a
              href={MobileAppStore.APPLE}
              target="_blank"
            >
              <img src={Appstore} alt="Appstore" />
            </a>
            
          </button>
        </div>
      </div>
    </div>
  );
};

export default BoxDownload;
