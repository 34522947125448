import "./NavBlog.css";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useCategory } from "@/hook/useCategory";

const NavBlog = () => {
  const location = useLocation();
  const currentPath = location?.pathname;
  const params = useParams();
  const { category, loading } = useCategory();

  return (
    <div className="nav_blog">
      {/* Menu */}
      <div className="nav_blog_inner">
        {!loading ? (
          <ul>
            <li>
              <Link
                to="/blog/latest"
                className={`title_medium color_primary ${
                  currentPath === "/blog/latest" && "gradient-text"
                }`}
              >
                Mới nhất
              </Link>
            </li>
            {category?.map((nav, i) => (
              <li key={i}>
                <Link
                  to={`/blog/${nav?.attributes?.name}?type=${nav?.id}`}
                  className={`title_medium color_primary ${
                    params?.dm1 === nav?.attributes?.name && "gradient-text"
                  }`}
                >
                  {nav?.attributes?.name}
                </Link>
              </li>
            ))}
            <li>
              <Link
                to="/blog/news"
                className={`title_medium color_primary ${
                  currentPath === "/blog/news" && "gradient-text"
                }`}
              >
                Tin tức
              </Link>
            </li>
          </ul>
        ) : (
          <Spinner animation="border" role="status" size="sm"></Spinner>
        )}
      </div>

      {/* Search */}
      <div>{/* <SearcSvg /> */}</div>
    </div>
  );
};

export default NavBlog;
