import { Outlet, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import SideBar from "@/components/Support/SideBar/SideBar";
import BreadcrumbComponent from "@/components/Breadcrumb/Breadcrumb";
import BgImage from '@/assets/images/background/decoration.png'
const SupportLayout = () => {
  const breadcrumbItems = [
    { text: "Trang chủ", href: "/" },
    { text: "Sản phẩm", href: "/product" },
    { text: "Hỗ trợ khách hàng", href: "/ho-tro-khach-hang" },
  ];
  return (
    <>
      <div style={{backgroundImage: `url(${BgImage})`, backgroundSize: "contain", backgroundRepeat: 'no-repeat'}}>
        <Container className="mt-4 mb-5">
          <Row>
            <Col xs={12} md={3}>
              <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />
              <SideBar />
            </Col>
            <Col xs={12} md={9}>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SupportLayout;
