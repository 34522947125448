import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./ContentBlock.css";
function ContentBlock({data}) {
  return (
    <Card className="contentblock-container d-grid gap-3 mt-3">
      <Card.Img
        variant="top"
        src={require(`@/assets/images/picture/jpg/${data.image}`)}
      />
      <Card.Body className="text-center px-0">
        <div className="contentblock-body">
          <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
            {/* <Card.Title style={{ fontSize: "22px" }}>{data.title}</Card.Title> */}
            <Card.Text className="contentblock-sub-title">{data.desc}</Card.Text>
          </div>
          
        </div>
      </Card.Body>
    </Card>
  );
}

export default ContentBlock;
