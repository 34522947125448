import { ReactComponent as SearcSvg } from "../assets/images/icon/svg/search.svg";
import { ReactComponent as AuthorSvg } from "../assets/images/icon/svg/author.svg";
import { ReactComponent as CalendarSvg } from "../assets/images/icon/svg/calendar.svg";
import { ReactComponent as ArrowRightSvg } from "../assets/images/icon/svg/arrow_right.svg";

import { ReactComponent as EmailtSvg } from "../assets/images/icon/svg/email.svg";
import { ReactComponent as PhoneSvg } from "../assets/images/icon/svg/phone.svg";
import { ReactComponent as AdressSvg } from "../assets/images/icon/svg/address.svg";

import { ReactComponent as TwSvg } from "../assets/images/icon/svg/tw.svg";
import { ReactComponent as FbSvg } from "../assets/images/icon/svg/fb.svg";
import { ReactComponent as InSvg } from "../assets/images/icon/svg/in.svg";
import { ReactComponent as GitSvg } from "../assets/images/icon/svg/git.svg";

import { ReactComponent as LinSvg } from "../assets/images/icon/svg/lin.svg";

export const icons = {
  SearcSvg,
  AuthorSvg,
  CalendarSvg,
  ArrowRightSvg,
  EmailtSvg,
  PhoneSvg,
  AdressSvg,

  TwSvg,
  FbSvg,
  InSvg,
  GitSvg,
  LinSvg,
};
