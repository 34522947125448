import React from "react";
import Banner from "./_components/Banner/Banner";
import FeaturedArticle from "./_components/FeaturedArticle/FeaturedArticle";
import RegisterBox from "../RegisterBox/RegisterBox";
import BoxDownload from "../BoxDownload/BoxDownload";
import ReactGA from "react-ga4";

const GA_TRACKING_ID = "G-2WCD4SGWB8";

ReactGA.initialize(GA_TRACKING_ID);

ReactGA.send({
  hitType: "Blog View",
  page: window.location.pathname,
});

const Blog = () => {
  return (
    <div className="blog_page">
      {/* Banner */}
      <Banner />

      {/* 1 cái gì đấy mà design chưa định nghĩa */}
      <div className="blog_caigiaynhi"> </div>

      {/* Bài viết nổi bật */}
      <FeaturedArticle />

      <div className="form_mobile">
        <RegisterBox />
        <BoxDownload />
      </div>
    </div>
  );
};

export default Blog;
