/* eslint-disable react-hooks/exhaustive-deps */
import { getCategoryApi } from "@/services/blog/blog";
import { useEffect, useState } from "react";

export const useCategory = () => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(undefined);

  const getCategory = async () => {
    setLoading(true);
    const res = await getCategoryApi();
    setLoading(false);
    setCategory(res?.data);
  };

  useEffect(() => {
    getCategory();
  }, []);

  return { category, loading };
};
