import React, { useEffect } from "react";
import styles from "./style.module.css";
import WrapperZoomOnHover from "@/components/Wrappers/WrapperLink";

import { useArticleState } from "@/stores/useArticleState";
import { icons } from "@/themes/images";
import ShareTools from "./components/ShareTools";
import Ricktext from "./components/RichText";
import { useParams } from "react-router-dom";
import { useGetDetailArticle, useGetRelatedArticles } from "@/services/new/apiMutations/article.mutation";
import {Helmet} from "react-helmet";
export default function JournalismDetail() {
  const { id } = useParams();
  
  const { mutate: requestGetRelatedArticles } = useGetRelatedArticles();
  const { mutate: requestGetArticleDetail } = useGetDetailArticle();
  
  useEffect(() => {
    if (id) {
      requestGetArticleDetail(id);
      requestGetRelatedArticles(id);
    }
  }, [id]);

  const { articleDetail, categoryList } = useArticleState();
  const { category_id, created_date, content, id: articleId } = articleDetail || {};

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{articleDetail?.title}</title>
        <meta name="description" content={articleDetail?.description}  />

        <meta content="news" name="medium" />
        <meta name="copyright" content="FinHub" />
        <meta name="author" content="FinHub"/>
        <meta property="og:title" content={articleDetail?.title} />
        <meta property="og:description" content={articleDetail?.description} />
        <meta property="og:image" content={articleDetail?.thumbnail || 'https://finhub.com.vn/static/media/logo.92d5cfa0c18dfdef97a60e4677e88e33.svg'} />
        <meta property="og:url" content={`https://finhub.com.vn/article/${articleDetail?.id}`} />
        <meta property="og:type" content="article" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="354"/>


        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={articleDetail?.title} />
        <meta name="twitter:description" content={articleDetail?.description} />
        <meta name="twitter:image" content={articleDetail?.thumbnail || 'https://finhub.com.vn/static/media/logo.92d5cfa0c18dfdef97a60e4677e88e33.svg'} />
      </Helmet>

      {/* Header */}
      <div className={styles.header}>
        <WrapperZoomOnHover>
          <p className={`${styles.topic} primary-color`}>
            {categoryList.find((category) => category.id === category_id)?.name}
          </p>
        </WrapperZoomOnHover>

        <img src={icons.divider} />
        <p className={styles.date}>{created_date && new Date(created_date)?.toLocaleDateString()}</p>
      </div>
      

      {/* Title */}
      <p className={styles.title}>{articleDetail?.title}</p>
      {/* {
        articleDetail?.thumbnail && <img src={articleDetail?.thumbnail} />
      } */}
      

      {/* Body */}
      <Ricktext content={content} />

      {/* Footer */}
      <ShareTools post_id={articleId} />
    </div>
  );
}