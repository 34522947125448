import React, { useState } from "react";
import { NumericFormat } from "react-number-format";

import styles from "./MoPhongDauTuQuyMo.module.css";
import { simData } from "./SimData";
import {
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  Label,
  Line,
} from "recharts";
import BreadcrumbComponent from "@/components/Breadcrumb/Breadcrumb";
const breadcrumbItems = [
  { text: "Trang chủ", href: "/" },
  { text: "Công Cụ", href: "/cong-cu" },
  // { text: "Chứng chỉ quỹ mở", href: "/product/chung-chi-quy-mo" },
  { text: "Mô phỏng đầu tư quỹ mở", href: "/cong-cu/mo-phong-dau-tu-quy-mo" },
];

export default function MoPhongDauTuQuyMo() {
  
  const [initValue, setInitValue] = useState(0);
  const [monthlyValue, setMonthlyValue] = useState(0);
  const [saving, setSaving] = useState(0);
  const [mfMin, setMfMin] = useState(0);
  const [mfMax, setMfMax] = useState(0);
  const [activeYear, setActiveYear] = useState(0);
  const [chartData, setChartData] = useState(simData);
  const i = 0.07;
  const i_mf_min = 0.1;
  const i_mf_max = 0.2;
  const c = 12;
  const n = 10;

  const UpdateChartData = () => {
    let p = parseInt(initValue);
    let r = parseInt(monthlyValue);

    for (let j = 0; j < simData.length; j++) {
      simData[j].saving =
        p * Math.pow(1 + i / c, j * c) +
        (r * (Math.pow(1 + i / c, j * c) - 1)) / (i / c);
      simData[j].mf[0] =
        p * Math.pow(1 + i_mf_min / c, j * c) +
        (r * (Math.pow(1 + i_mf_min / c, j * c) - 1)) / (i_mf_min / c);
      simData[j].mf[1] =
        p * Math.pow(1 + i_mf_max / c, j * c) +
        (r * (Math.pow(1 + i_mf_max / c, j * c) - 1)) / (i_mf_max / c);
    }

    setChartData(simData);
  };

  const InterestCalculation = (y) => {
    let p = parseInt(initValue);
    let r = parseInt(monthlyValue);
    let savingValue = Math.floor(
      p * Math.pow(1 + i / c, y * c) +
        (r * (Math.pow(1 + i / c, y * c) - 1)) / (i / c)
    );
    let mfMinValue = Math.floor(
      p * Math.pow(1 + i_mf_min / c, y * c) +
        (r * (Math.pow(1 + i_mf_min / c, y * c) - 1)) / (i_mf_min / c)
    );
    let mfMaxValue = Math.floor(
      p * Math.pow(1 + i_mf_max / c, y * c) +
        (r * (Math.pow(1 + i_mf_max / c, y * c) - 1)) / (i_mf_max / c)
    );
    setSaving(savingValue);
    setMfMin(mfMinValue);
    setMfMax(mfMaxValue);
    UpdateChartData();
  };

  const onClickHandle = () => {
    setActiveYear(n);
    InterestCalculation(n);
  };

  const updateInitValue = (event) => {
    let value = event.target.value || "0";
    value = convertDollarStringToNumber(value);
    value = parseInt(value);
    setInitValue(value);
  };

  const updateMonthlyValue = (event) => {
    let value = event.target.value || "0";
    value = convertDollarStringToNumber(value);
    value = parseInt(value);
    setMonthlyValue(value);
  };

  const DataFormater = (number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  const handleMouseMove = (e) => {
    let { activeLabel } = e;
    if (activeLabel > 0 && activeLabel !== activeYear) {
      setActiveYear(activeLabel);
      InterestCalculation(activeLabel);
    }
  };

  function convertDollarStringToNumber(dollarString) {
    // Remove commas from the string and convert to number
    return parseFloat(dollarString.replace(/[$,]/g, ""));
  }

  return (
    <div className={styles.container + " container mt-3 mb-5"}>
      <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />
        <div className={styles.title}>Mô phỏng đầu tư quỹ mở</div>
      <div className="row gx-5">
        <div className="col-xs-12 col-md-4">
          <div className={styles.left}>
            <div>
              <div className={styles.label}>Đầu tư ban đầu</div>
              <div>
                <NumericFormat
                  thousandSeparator={true}
                  suffix={"đ"}
                  value={0}
                  className="form-control"
                  onChange={updateInitValue}
                  style={{ width: "70%" }}
                />
              </div>
            </div>
            <div>
              <div className={styles.label}>Đầu tư hàng tháng</div>
              <div>
                <NumericFormat
                  thousandSeparator={true}
                  suffix={"đ"}
                  value={0}
                  className="form-control"
                  onChange={updateMonthlyValue}
                  style={{ width: "70%" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-8">
          <div className={styles.right}>
            <div className="d-grid gap-3">
              <div className={styles.right_title}>
                Trong {activeYear} năm, bạn có thể nhận được
              </div>
              <div className="d-flex gap-5">
                <div>
                  <div>Gửi tiết kiệm</div>
                  <div className={styles.money_save}>
                    {saving.toLocaleString()}
                  </div>
                </div>
                <div>
                  <div>Đầu tư quỹ mở*</div>
                  <div className={styles.money_profit + " primary-color"}>
                    {mfMin.toLocaleString()} - {mfMax.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                  width={250}
                  height={250}
                  data={chartData}
                  margin={{ top: 30, bottom: 30 }}
                  onMouseMove={handleMouseMove}
                >
                  <CartesianGrid vertical={false} strokeDasharray="3 3" />
                  <XAxis dataKey="year">
                    <Label value={"năm"} position="bottom" />
                  </XAxis>
                  <YAxis tickFormatter={DataFormater} />
                  <Area dataKey="mf" stroke="#1870F5" fill="#0DA6C2" />
                  <Line
                    dataKey="saving"
                    stroke="black"
                    strokeWidth="2"
                    dot={false}
                  />
                  <Tooltip
                    cursor={false}
                    contentStyle={{ visibility: "hidden" }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div style={{fontSize: '13px'}}>*Dựa vào lãi suất ngân hàng và tỉ suất lợi nhuận quỹ mở trong quá khứ, kết quả có thể khác trong tương lai</div>
        </div>
      </div>
    </div>
  );
}
