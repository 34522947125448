import axiCms from "../apiCms";

export const getCategoryApi = async () => {
  try {
    const res = await axiCms.get(`/api/categories?populate=*`);

    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryByIdApi = async (id) => {
  try {
    const res = await axiCms.get(`/api/categories/${id}?populate=*`);

    if (res.status === 200) {
      return res.data;
    } else {
      console.log("looxi");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDM2 = async (id) => {
  try {
    const res = await axiCms.get(
      `/api/dm-cap-2s/${id}?populate=bai_viets.thumbnail&populate=dm_cap_3s`
    );

    if (res.status === 200) {
      return res.data;
    } else {
      console.log("looxi");
    }
  } catch (error) {
    console.log(error);
  }
};
export const getTabViewsApi = async () => {
  try {
    const res = await axiCms.get(`/api/dm-cap-2s`);

    if (res.status === 200) {
      return res.data;
    } else {
      console.log("looxi");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDM3 = async (id) => {
  try {
    const res = await axiCms.get(
      `/api/dm-cap-3s/${id}?populate=bai_viets.thumbnail&populate=bai_viets.category_name`
    );

    if (res.status === 200) {
      return res.data;
    } else {
      console.log("looxi");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllDM3 = async () => {
  try {
    const res = await axiCms.get(`/api/dm-cap-3s`);

    if (res.status === 200) {
      return res.data;
    } else {
      console.log("looxi");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDetails = async (id) => {
  try {
    const res = await axiCms.get(`/api/articles/${id}?populate=*`);

    if (res.status === 200) {
      return res.data;
    } else {
      console.log("looxi");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllPost = async () => {
  try {
    const res = await axiCms.get(`/api/articles?populate=*`);

    if (res.status === 200) {
      return res.data;
    } else {
      console.log("looxi");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllPostNews = async () => {
  try {
    const res = await axiCms.get(
      `/api/dm-cap-2s/7?populate=bai_viets.thumbnail`
    );

    if (res.status === 200) {
      return res.data;
    } else {
      console.log("looxi");
    }
  } catch (error) {
    console.log(error);
  }
};
export const userRegister = async (user) => {
  try {
    const res = await axiCms.post(`/api/user-registers`, {
      data: user,
    });

    if (res.status === 200) {
      return res.data;
    } else {
      console.log("looxi");
    }
  } catch (error) {
    console.log(error);
  }
};
