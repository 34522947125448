import React, { useEffect, useState } from "react";
import "./Classify.css";

import { getTabViewsApi } from "@/services/blog/blog";

const TabViews = ({ listTab, tabViewsResult }) => {
  const [active, setActive] = useState(1);

  const [listTabViews, setLisTabViews] = useState([]);

  const getTabViews = async () => {
    try {
      const res = await getTabViewsApi();

      setLisTabViews(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTabViews();
  }, []);

  const handleClick = (index, title) => {
    setActive(index);
    tabViewsResult(title);
  };

  return (
    <div className="tab_views">
      <ul>
        <li
          className={`color_primary ${active === 1 && "tab_views_active"}`}
          onClick={() => handleClick(1, "all")}
        >
          Tất cả
        </li>
        {listTabViews?.map((tabview, i) => (
          <li
            key={i}
            className={`color_primary ${
              active === i + 2 && "tab_views_active"
            }`}
            onClick={() => handleClick(i + 2, tabview?.attributes?.name)}
          >
            {tabview?.attributes?.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabViews;
