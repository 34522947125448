import React from "react";
import styles from "./style.module.css";
import { icons } from "@/themes/images";
import { ArticalCardType, ArticleInterface } from "@/interfaces/Article";
import WrapperZoomOnHover from "@/components/Wrappers/WrapperLink";
import { useArticleState } from "@/stores/useArticleState";
import { useNavigate } from "react-router-dom";
interface ArticleCardComponentProps {
  article: ArticleInterface;
}

function convertToLocalDate(date: string) {
  return new Date(date).toLocaleDateString();
}

export default function ArticleCardComponent({
  article,
}: ArticleCardComponentProps) {
  const { categoryList, setArticleList } = useArticleState();
  const navigate = useNavigate();
  const { setRelatedArticleList } = useArticleState();
  function handleClick() {
    setRelatedArticleList([]);
    setArticleList([]);
    navigate(`/article/${article.id}`);
  }
  return (
    <div onClick={handleClick} className={styles.container + " container"}>
      <div className="row">
        <div className="col-md-12 col-lg-5 col-xl-4  px-0">
          {article.thumbnail ? (
            <img src={article.thumbnail} className={styles.thumbnail} />
          ) : (
            <img
              src={
                "https://e0.pxfuel.com/wallpapers/963/105/desktop-wallpaper-finance-50-financial.jpg"
              }
              className={styles.thumbnail}
            />
          )}
        </div>
        <div className="col-md-12 col-lg-7 col-xl-8 px-0">
          <div className={styles.right_container}>
            <div className={styles.header}>
              <WrapperZoomOnHover>
                <p className={styles.topic + " primary-color"}>
                  {article.category_id &&
                    categoryList.find(
                      (category) => category.id === article.category_id
                    )?.name}
                </p>
              </WrapperZoomOnHover>

              <img src={icons.divider} />
              <p className={styles.date}>
                {convertToLocalDate(article.created_date)}
              </p>
            </div>
            <div>
              <p className={styles.title}>{article.title}</p>
            </div>
            <div>
              <p className={styles.content}>{article.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
