import React from "react";

import "./Ricktext.css";

const Ricktext = ({ content }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: content,
      }}
      className="rich_text"
    ></div>
  );
};

export default Ricktext;
