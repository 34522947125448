import { useState, useEffect } from "react";
import "./ChungChiQuyMoDetail.css";
import BgImage from "@/assets/images/picture/png/product-ccqm-detail2.png";
import Utils from "@/utils";
import { Tooltip } from "react-tooltip";
import useWindowDimensions from "@/hook/useWindowDimensions";

function ChungChiQuyMoDetail() {
  const { height, width } = useWindowDimensions();
  const [algorithmContent, setAlgorithmContent] = useState({
    title: "Quỹ Tiền tệ",
    content:
      "Tập trung 100% khoản đầu tư vào các chứng khoán nợ ngắn hạn, có tính thanh khoản cao bao gồm tín phiếu kho bạc, tiền mặt và các chứng khoán tương đương tiền. Bằng cách duy trì khoảng thời gian ngắn, loại quỹ mở này có tính thanh khoản và độ an toàn cao nhất trong các loại hình quỹ mở. Vì vậy loại quỹ này thường được các nhà đầu tư lựa chọn khi mức độ chấp nhận rủi ro thấp. Do lợi nhuận cũng nhỏ nhất so với những loại quỹ mở khác.",
  });
  const [algorithmList, setAlgorithmList] = useState([
    {
      name: "Quỹ tiền tệ",
      active: true,
      content:
        "Tập trung 100% khoản đầu tư vào các chứng khoán nợ ngắn hạn, có tính thanh khoản cao bao gồm tín phiếu kho bạc, tiền mặt và các chứng khoán tương đương tiền. Bằng cách duy trì khoảng thời gian ngắn, loại quỹ mở này có tính thanh khoản và độ an toàn cao nhất trong các loại hình quỹ mở. Vì vậy loại quỹ này thường được các nhà đầu tư lựa chọn khi mức độ chấp nhận rủi ro thấp. Do đó lợi nhuận cũng nhỏ nhất so với những loại quỹ mở khác.",
    },
    {
      name: "Quỹ cổ phiếu",
      active: false,
      content:
        "Tối thiểu 80% tài sản đầu tư vào cổ phiếu, giúp nhà đầu tư nắm bắt được các cơ hội tăng giá của Thị trường chứng khoán. Do cổ phiếu có tính chất biến động cao, quỹ cổ phiếu mang theo mức rủi ro lớn, nhưng cũng mang lại cơ hội lợi nhuận cao nếu thị trường tăng giá. Quỹ cổ phiếu là lựa chọn phổ biến cho nhà đầu tư muốn tham gia vào thị trường cổ phiếu mà không cần quản lý trực tiếp danh mục đầu tư của mình.",
    },
    {
      name: "Quỹ trái phiếu",
      active: false,
      content:
        "Tối thiểu 80% tài sản nằm trong trái phiếu và các tài sản có thu nhập cố định để mang lại lợi nhuận ổn định và cao hơn gửi tiết kiệm cho nhà đầu tư. Quỹ trái phiếu giống như gửi tiết kiệm và thường có lãi cao hơn. So với quỹ cổ phiếu, quỹ trái phiếu thường mang lại mức rủi ro thấp hơn vì trái phiếu thường ổn định và có ít biến động hơn so với cổ phiếu.Quỹ trái phiếu là một lựa chọn phổ biến cho những nhà đầu tư muốn cân bằng rủi ro trong danh mục đầu tư của họ.",
    },
    {
      name: "Quỹ cân bằng",
      active: false,
      content:
        "Quỹ nắm 50% cổ phiếu và 50% trái phiếu. Với sự kết hợp giữa cổ phiếu và trái phiếu, quỹ cân bằng cung cấp sự đa dạng hóa rủi ro. Khi một phần của danh mục gặp khó khăn, phần khác có thể đóng vai trò làm giảm áp lực. Quỹ cân bằng mang lại sự kết hợp linh hoạt giữa cổ phiếu và trái phiếu, phù hợp cho những nhà đầu tư muốn sự đa dạng hóa trong danh mục đầu tư của họ.",
    },
  ]);

  const session3Content = [
    {
      title: "Đa dạng hoá rủi ro",
      content:
        "Quỹ mở đầu tư vào một loạt các tài sản khác nhau như cổ phiếu, trái phiếu và có thể cả tiền mặt. Điều này giúp đa dạng hóa rủi ro, giảm thiểu ảnh hưởng của sự suy giảm giá trị của một loại tài sản cụ thể.",
    },
    {
      title: "Tiền của bạn được quản lý bởi chuyên gia",
      content:
        "Quỹ mở cho phép nhà đầu tư tiếp cận với các công ty quản lý quỹ chuyên nghiệp, người có kinh nghiệm chuyên môn và có nguồn lực để phân tích, theo dõi khoản đầu tư tại Quỹ cho nhà đầu tư. Nhờ đó mà nhà đầu tư giải phóng được thời gian cho mình, không cần theo dõi thị trường thường xuyên.",
    },
    {
      title: "Tiết kiệm chí phí đầu tư và quản lý thuế hiệu quả",
      content:
        "Với một khoản đầu tư vừa và nhỏ, nhà đầu tư có thể tham gia đầu tư vào nhiều lĩnh vực khác nhau từ cổ phiếu, trái phiếu, tiền gửi trong khi đầu tư trực tiếp vào các lĩnh vực này rất khó có thể thực hiện trực tiếp với số vốn nhỏ. Ngoài ra quỹ mở thường được quản lý để giảm thiểu ảnh hưởng thuế đối với nhà đầu tư vì giao dịch mua bán chứng khoán trong quỹ không tạo ra nhiều thuế cá nhân.",
    },
    {
      title: "Tiện Lợi và Dễ Tiếp Cận",
      content:
        "Mua và bán chứng chỉ quỹ mở thường được thực hiện thông qua trang web trực tuyến của công ty quản lý quỹ, công ty chứng khoán hoặc đại lý phân phối, mang lại sự tiện lợi và dễ tiếp cận cho nhà đầu tư.",
    },
    {
      title: "Tỷ lệ hoàn vốn và kiểm soát rủi ro tốt",
      content:
        "Với mục tiêu xuyên suốt trong thời gian hoạt động của quỹ là bảo vệ lợi ích và gia tăng giá trị cho người sở hữu chứng chỉ quỹ, phù hợp với đầu tư dài hạn hoặc trung hạn. Đầu tư vào quỹ mở có khả năng tạo ra lợi nhuận cao hơn với khả năng kiểm soát rủi ro tốt, bởi vì quỹ mở áp dụng nguyên tắc đa dạng hóa.",
    },
    {
      title: "Danh Mục Đầu Tư Được Công Bố",
      content:
        "Quỹ mở thường công bố danh mục đầu tư của mình, giúp nhà đầu tư biết được tài sản cụ thể mà họ đang đầu tư.",
    },
    {
      title: "Điều Chỉnh Danh Mục Dễ Dàng",
      content:
        "Nhà đầu tư có thể điều chỉnh danh mục đầu tư của mình một cách dễ dàng thông qua mua bán cổ phần quỹ mở.",
    },
    {
      title: "Thanh khoản cao",
      content:
        "Trong trường hợp cần thiết, nhà đầu tư cần phải sử dụng tiền, chứng chỉ quỹ có thể chuyển thành tiền mặt nhanh chóng và dễ dàng. Nhà đầu tư có thể bán lại cho Quỹ một phần hoặc toàn bộ số chứng chỉ quỹ đang sở hữu.",
    },
    {
      title: "Minh bạch",
      content:
        "Cùng với sự kiểm soát chặt chẽ của ngân hàng giám sát, mọi thông tin về quá trình hoạt động đều được gửi tới nhà đầu tư đầy đủ, rõ ràng và thường xuyên.",
    },
  ];

  function algorithmClickHandler(index) {
    const newList = [];
    for (let item of algorithmList) {
      if (algorithmList.indexOf(item) === index) {
        item.active = true;
        newList.push(item);
      } else {
        item.active = false;
        newList.push(item);
      }
    }

    setAlgorithmList(newList);
  }
  useEffect(() => {
    for (let item of algorithmList) {
      if (item.active) {
        setAlgorithmContent({
          title: item.name,
          content: item.content,
        });
        break;
      }
    }
  }, [algorithmList]);

  return (
    <>
      <div className="d-grid gap-5">
        <div className="container-fluid">
          {/* Desktop */}
          <div className="row ccqm-detail-banner">
            <div
              className="col-xs-12 col-md-6 col-lg-6 my-3 px-0 d-grid gap-3 ccqm-detail-left"
              style={{
                position: "relative",
              }}
            >
              <img
                className="img-fluid ccqm-session1-left-image"
                src={BgImage}
              />
              <div className="ccqm-session1-container-text">
                <div className="ccqm-detail-banner-title">
                  Chứng chỉ quỹ mở - Bước đi đầu tiên đến sự thành công tài
                  chính!
                </div>

                <div>
                  <p className="ccqm-detail-banner-text">
                    Quỹ mở là quỹ được góp vốn bởi nhiều nhà đầu tư có cùng mục
                    tiêu đầu tư, được quản lý chuyên nghiệp bởi một công ty quản
                    lý quỹ đã đăng ký và chịu sự giám sát của Ủy ban Chứng khoán
                    Nhà nước.
                  </p>
                </div>

                <div className="container ps-0 ">
                  <div className="row">
                    <div className="col-6 pe-3">
                      <div className="ccqm-detail-banner-sub-title">
                        Bắt đầu với số vốn ít
                      </div>
                      <div>
                        <p className="ccqm-detail-banner-text">
                          Có thể sở hữu chứng chỉ quỹ chỉ từ 100.000 đồng
                        </p>
                      </div>
                    </div>
                    <div className="col-6 pe-3">
                      <div className="ccqm-detail-banner-sub-title">
                        Dễ dàng, nhanh chóng
                      </div>
                      <div>
                        <p className="ccqm-detail-banner-text">
                          Có thể mua chứng chỉ quỹ chỉ với 1 lệnh đặt trên điện
                          thoại
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="ccqm-detail-banner-sub-title">
                        Hỗ trợ từ chuyên gia
                      </div>
                      <div>
                        <p className="ccqm-detail-banner-text">
                          Quỹ được quản lý bởi các chuyên gia tài chính
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="ccqm-detail-banner-sub-title">
                        Đa dạng danh mục
                      </div>
                      <div>
                        <p className="ccqm-detail-banner-text">
                          Đa dạng hóa danh mục với nhiều loại cổ phiếu và trái
                          phiếu
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center ccqm-detail-right">
              <img
                className="img-fluid"
                // height={450}
                src={require("@/assets/images/picture/png/product-ccqm-detail1.png")}
              />
            </div>
          </div>
          {/* Mobile */}
          <div className="row ccqm-detail-banner-mb">
            <div
              className="col-xs-12 col-md-6 col-lg-6 my-3 px-0 d-grid gap-3 ccqm-detail-left"
              style={{
                position: "relative",
              }}
            >
              <div className="ccqm-session1-container-text">
                <div className="ccqm-detail-banner-title">
                  Chứng chỉ quỹ mở - Bước đi đầu tiên đến sự thành công tài
                  chính!
                </div>

                <div>
                  <p className="ccqm-detail-banner-text">
                    Quỹ mở là một gói đầu tư được góp vốn bởi nhiều nhà đầu tư
                    có cùng mục tiêu đầu tư, được quản lý chuyên nghiệp bởi một
                    công ty quản lý quỹ đã đăng ký và chịu sự giám sát của Ủy
                    ban Chứng khoán Nhà nước.
                  </p>
                </div>

                <div className="container ps-0 ">
                  <div className="row">
                    <div className="col-12 pe-3">
                      <div className="ccqm-detail-banner-sub-title">
                        Bắt đầu với số vốn ít
                      </div>
                      <div>
                        <p className="ccqm-detail-banner-text">
                          Có thể sở hữu chứng chỉ quỹ chỉ từ 100.000 đồng
                        </p>
                      </div>
                    </div>
                    <div className="col-12 pe-3">
                      <div className="ccqm-detail-banner-sub-title">
                        Dễ dàng, nhanh chóng
                      </div>
                      <div>
                        <p className="ccqm-detail-banner-text">
                          Có thể mua chứng chỉ quỹ chỉ với 1 lệnh đặt trên điện
                          thoại
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="ccqm-detail-banner-sub-title">
                        Hỗ trợ từ chuyên gia
                      </div>
                      <div>
                        <p className="ccqm-detail-banner-text">
                          Quỹ được quản lý bởi các chuyên gia tài chính
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="ccqm-detail-banner-sub-title">
                        Đa dạng danh mục
                      </div>
                      <div>
                        <p className="ccqm-detail-banner-text">
                          Đa dạng hóa danh mục với nhiều loại cổ phiếu và trái
                          phiếu
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xs-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center ccqm-detail-right">
              <img
              className="img-fluid"
                src={require("@/assets/images/picture/png/product-ccqm-detail1.png")}
              />
            </div> */}
          </div>
        </div>
        <div className="container text-center">
          <div className="row">
            <div style={{ fontSize: "40px", fontWeight: 500 }}>
              Cơ chế hoạt động của quỹ mở
            </div>
          </div>
          <div className="row">
            <img
              src={require("@/assets/images/picture/png/product-ccqm-detail3.png")}
            />
          </div>
        </div>

        <div
          className="container-fluid text-center py-5"
          style={{ background: "#F5FCFA" }}
        >
          <div className="container">
            <div className="row">
              <div style={{ fontSize: "40px", fontWeight: 500 }}>Thuật ngữ</div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className="text-start product-ccqm-card">
                  <div style={{ fontWeight: 500, fontSize: "22px" }}>
                    {algorithmContent.title}
                  </div>
                  <div>
                    <p>{algorithmContent.content}</p>
                  </div>
                </div>
              </div>
              <div className="text-start col-xs-12 col-md-6">
                {algorithmList.map((item, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => algorithmClickHandler(index)}
                      className={
                        "me-2 my-2 ccqm-algorithm-btn " +
                        (item.active ? "active" : "")
                      }
                    >
                      {item.name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid ccqm-session3-container">
          <div className="ccqm-session3-title primary-color text-center">
            9 lợi thế nổi trội của quỹ mở
          </div>
          <div className="d-flex justify-content-center">
            <img
              className="product-ccqm-session3-image"
              src={require("@/assets/images/picture/png/product-ccqm-detail4.png")}
            />
          </div>
          {session3Content.map((item, index) => {
            return (
              <>
                <div
                  className="product-session3-card"
                  id={"product-session3-card" + (index + 1)}
                >
                  <div className="product-session3-card-title primary-color">
                    {index + 1}
                    {". "}
                    {item.title}
                  </div>
                  <div className="product-session3-card-content">
                    {width > 768
                      ? new Utils().shortenText(item.content, 20)
                      : item.content}
                  </div>
                </div>

                <Tooltip
                  // key="top"
                  anchorSelect={"#product-session3-card" + (index + 1)}
                  content={item.content}
                  place="bottom"
                  className="tooltip-style"
                  // style={{background: "#fff", color: "#21233D", boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.10)", borderRadius: '20px', opacity: 1, width: "490px"}}
                />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ChungChiQuyMoDetail;
