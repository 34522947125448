import React from "react";
import Layout from "../_components/Layout/Layout";
import Card from "../_components/Card/Card";
import { useGetDm3 } from "@/hook/blog/useGetDm3";
import Loading from "../_components/Loading/Loading";

const DM3 = () => {
  const { data, loading } = useGetDm3();

  if (loading) {
    return <Loading />;
  }

  return (
    <Layout isOutstandingBox={false}>
      <div className="list_vertical">
        {data?.attributes?.bai_viets?.data?.map((item) => (
          <Card
            key={item?.id}
            thumbnail={item?.attributes?.thumbnail?.data?.attributes?.url}
            category={
              item?.attributes?.category_name?.data?.[0]?.attributes?.name
            }
            author={item?.attributes?.author}
            createdAt={item?.attributes?.createdAt}
            description={item?.attributes?.short_description}
            title={item?.attributes?.title}
            isSmall={true}
            id={item?.id}
          />
        ))}
      </div>
    </Layout>
  );
};

export default DM3;
