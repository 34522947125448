import { useLocation, useParams } from "react-router-dom";

export const useGetPathname = () => {
  const params = useParams();

  const location = useLocation();

  const dm1 = params?.dm1;
  const dm2 = params?.dm2;
  const dm3 = params?.dm3;

  const pathname = location?.pathname;
  const queryDM1 = new URLSearchParams(location.search).get("type");
  const queryDM2 = new URLSearchParams(location.search).get("type-dm2");
  const queryDM3 = new URLSearchParams(location.search).get("type-dm3");

  return {
    dm1,
    dm2,
    dm3,
    pathname,
    queryDM1,
    queryDM2,
    queryDM3,
  };
};
