export const simData = [
    {
        "year": 0,
        "saving":100000000,
        "mf": [
            100000000,
            100000000,
        ]
    },
    {
        "year": 1,
        "saving":230850000,
        "mf": [
            235500000,
            251000000
        ]
    },
    {
        "year": 2,
        "saving":370859500,
        "mf": [
            384550000,
            432200000
        ]
    },
    {
        "year": 3,
        "saving":520669665,
        "mf": [
            548505000,
            649640000
        ]
    },
    {
        "year": 4,
        "saving":680966541,
        "mf": [
            728855500,
            910568000
        ]
    },
    {
        "year": 5,
        "saving":852484199,
        "mf": [
            927241050,
            1223681600
        ]
    },
    {
        "year": 6,
        "saving":1036008093,
        "mf": [
            1145465155,
            1599417920
        ]
    },
    {
        "year": 7,
        "saving":1232378659,
        "mf": [
            1385511670,
            2050301504
        ]
    },
    {
        "year": 8,
        "saving":1442495166,
        "mf": [
            1649562837,
            2591361804
        ]
    },
    {
        "year": 9,
        "saving":1667319827,
        "mf": [
            1940019121,
            3240634165
        ]
    },
    {
        "year": 10,
        "saving":1907882215,
        "mf": [
            2259521033,
            4019760998
        ]
    }
]