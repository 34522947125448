import { getCategoryByIdApi } from "@/services/blog/blog";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useGetCategoryByIdApi = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);

  const queryParamsType = new URLSearchParams(location.search).get("type");

  const getCategory = async (id) => {
    setLoading(true);
    const res = await getCategoryByIdApi(id);
    setLoading(false);
    setData(res?.data);
  };

  useEffect(() => {
    getCategory(queryParamsType);
  }, [queryParamsType]);

  return { data, loading };
};
