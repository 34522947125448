import React, { useEffect, useState } from "react";

import "./Classify.css";
import TabViews from "./TabViews";
import Card from "../Card/Card";

const ClassifyList = ({ list }) => {
  const [listClassify, setListClassify] = useState([]);
  const [titleSelect, setTitleSelect] = useState("all");

  const tabViewsResult = (title) => setTitleSelect(title);

  useEffect(() => {
    if (titleSelect === "all") {
      setListClassify(list);
    } else {
      const listFilter = list?.filter(
        (item) =>
          item?.attributes?.category_name?.data?.[0]?.attributes?.name ===
          titleSelect
      );
      setListClassify(listFilter);
    }
  }, [titleSelect, list]);

  return (
    <div className="classify_list">
      {/* TabViews */}
      <TabViews tabViewsResult={tabViewsResult} />

      {/* List */}

      <div className="classify_list_inner">
        {listClassify?.map((item) => (
          <Card
            key={item?.id}
            thumbnail={item?.attributes?.thumbnail?.data?.attributes?.url}
            category={
              item?.attributes?.category_name?.data?.[0]?.attributes?.name
            }
            author={item?.attributes?.author}
            createdAt={item?.attributes?.createdAt}
            description={item?.attributes?.short_description}
            title={item?.attributes?.title}
            isSmall={true}
            id={item?.id}
          />
        ))}
      </div>
    </div>
  );
};

export default ClassifyList;
