import React from "react";
import "./System.css";
import CarouselSystem from "@/components/CarouselSystem/CarouselSystem";
import BannerBgImage from "@/assets/images/picture/png/system2.png";
import styles from "./System.module.css";
import MobileAppStore from "@/constants/mobile-store";
const System = () => {
  return (
    <>
      <div className="d-grid gap-5">
        <div className="container-fluid p-0">
          {/* pc version */}
          <div className="row system-banner mx-0">
            <div
              className="col-xs-12 col-lg-6 system-session1-left px-0"
              style={{ position: "relative" }}
            >
              <img
                className="img-fluid system-session1-left-image"
                src={require("@/assets/images/picture/png/system2.png")}
              />
              <div className="system-banner-left">
                <div className="system-banner-title ">
                  Xây dựng kế hoạch tài chính an toàn và dễ dàng với FinHub!
                </div>

                <div>
                  <p className="system-banner-text" style={{ color: "white" }}>
                    Ứng dụng FinHub là nền tảng cung cấp kết hợp kiến thức và
                    các công cụ giúp mọi người đưa ra các quyết định tài chính
                    phù hợp nhất với bản thân.
                  </p>
                </div>

                <div>
                  <p className="system-banner-text">
                    Bắt đầu ngay từ bây giờ cùng FinHub.
                  </p>
                </div>

                <div className="d-flex gap-5 mt-4">
                  <div>
                    <a href={MobileAppStore.ANDROID} target="_blank">
                      <img
                        src={
                          require("@/assets/images//icon/svg/google-play.svg")
                            .default
                        }
                        width={200}
                      />
                    </a>
                  </div>
                  <div>
                    <a href={MobileAppStore.APPLE} target="_blank">
                      <img
                        src={
                          require("@/assets/images//icon/svg//app-store.svg")
                            .default
                        }
                        width={200}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-lg-6 d-flex justify-content-center align-items-center system-session1-right">
              <img
                className="img-fluid system-session2-right-image"
                sy
                src={require("@/assets/images/picture/png/system1.png")}
              />
            </div>
          </div>
          {/* mobile version */}
          <div className="row system-banner-mb mx-0">
            <div
              className="col-xs-12 col-lg-6 system-session1-left px-0"
              style={{
                position: "relative",
                backgroundImage: `url(${BannerBgImage})`,
              }}
            >
              <div className="system-banner-left">
                <div className="system-banner-title">
                  Xây dựng kế hoạch tài chính an toàn và dễ dàng với FinHub!
                </div>

                <div>
                  <p style={{ color: "white" }}>
                    Ứng dụng FinHub là nền tảng cung cấp kết hợp kiến thức và
                    các công cụ giúp mọi người đưa ra các quyết định tài chính
                    phù hợp nhất với bản thân.
                  </p>
                </div>

                <div>
                  <p style={{ color: "white" }}>
                    Bắt đầu ngay từ bây giờ cùng FinHub.
                  </p>
                </div>

                <div className="d-flex gap-5">
                  <div>
                    <a href={MobileAppStore.ANDROID} target="_blank">
                      <img
                        src={
                          require("@/assets/images//icon/svg/google-play.svg")
                            .default
                        }
                        width={120}
                      />
                    </a>
                  </div>
                  <div>
                    <a href={MobileAppStore.APPLE} target="_blank">
                      <img
                        src={
                          require("@/assets/images//icon/svg/app-store.svg")
                            .default
                        }
                        width={120}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* =================================== */}
          <div className="row" style={{ background: "#C7FCFF" }}></div>
        </div>
        <CarouselSystem />

        <div
          className="d-grid gap-5"
          style={{
            background:
              "linear-gradient(187deg, rgba(245, 252, 250, 0.00) 4.47%, rgba(6, 162, 221, 0.08) 60.65%)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="system-session3-title">
                Hướng dẫn sử dụng ứng dụng FinHub
              </div>
            </div>

            <div className="row py-5 gx-3">
              <div className="col-xs-12 col-md-6 " style={{ margin: "auto" }}>
                <div className="system-session3-card">
                  <div className="system-session3-sub-title primary-color">
                    Tạo tài khoản trên App FinHub
                  </div>
                  <div>
                    <ul className={styles.ul}>
                      <li>
                        Nhà đầu tư điền đầy đủ thông tin Email và số điện thoại
                        để tạo tài khoản trên App
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-xs-12 col-md-6 d-flex justify-content-center align-items-center system-session3-image"
                style={{ margin: "auto" }}
              >
                <img className="img-fluid" src={require("@/assets/images/picture/png/system4.png")} />
              </div>
            </div>

            {/*  */}

            <div className="row py-5">
              <div
                className="col-xs-12 col-md-6 d-flex justify-content-center align-items-center system-session3-image"
                style={{ margin: "auto" }}
              >
                <img className="img-fluid" src={require("@/assets/images/picture/png/system5.png")} />
              </div>

              <div className="col-xs-12 col-md-6 " style={{ margin: "auto" }}>
                <div className="system-session3-card">
                  <div className="system-session3-sub-title primary-color">
                    Cập nhật đầy đủ và chi tiết thông tin quỹ mở
                  </div>
                  <div>
                    <ul className={styles.ul}>
                      <li>Bước 1: Nhà đầu tư đăng nhập App FinHub</li>
                      <li>Bước 2: Chọn quỹ</li>
                      <li>Bước 3: Theo dõi thông tin quỹ</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}

            <div className="row py-5">
              <div className="col-xs-12 col-md-6 " style={{ margin: "auto" }}>
                <div className="system-session3-card">
                  <div className="system-session3-sub-title primary-color">
                    Tư vấn tự động Robo Advisor
                  </div>
                  <div>
                    <ul className={styles.ul}>
                      <li>Bước 1: Nhà đầu tư đăng nhập App FinHub</li>
                      <li>Bước 2: Chọn Robo Advisor</li>
                      <li>
                        Bước 3: Khám phá kế hoạch đầu tư phù hợp với Robo
                        Advisor
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-xs-12 col-md-6 d-flex justify-content-center align-items-center system-session3-image"
                style={{ margin: "auto" }}
              >
                <img className="img-fluid"  src={require("@/assets/images/picture/png/system6.png")} />
              </div>
            </div>

            {/*  */}

            <div className="row py-5">
              <div
                className="col-xs-12 col-md-6 d-flex justify-content-center align-items-center system-session3-image"
                style={{ margin: "auto" }}
              >
                <img className="img-fluid" src={require("@/assets/images/picture/png/system7.png")} />
              </div>

              <div className="col-xs-12 col-md-6 " style={{ margin: "auto" }}>
                <div className="system-session3-card">
                  <div className="system-session3-sub-title primary-color">
                    Cập nhật kiến thức và tin tức mới nhất trên Blog
                  </div>
                  <div>
                    <ul className={styles.ul}>
                      <li>Bước 1: Đăng nhập vào App FinHub</li>
                      <li>Bước 2: Chọn Blog</li>
                      <li>Bước 3: Tìm hiểu kiến thức tài chính và những thông tin mới nhất về thị trường</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default System;
