import React from "react";
import "./Banner.css";
import RegisterBox from "@/components/RegisterBox/RegisterBox";
import BoxDownload from "@/components/BoxDownload/BoxDownload";
import Card from "../Card/Card";

const BannerNews = ({ data, category }) => {
  return (
    <div className="banner_new container">
      {/* Banner Image */}
      <div style={{ flex: 1 }}>
        <Card
          key={data?.[0]?.id}
          thumbnail={data?.[0]?.attributes?.thumbnail?.data?.attributes?.url}
          category={category}
          author={data?.[0]?.attributes?.author}
          createdAt={data?.[0]?.attributes?.createdAt}
          description={data?.[0]?.attributes?.title}
          title={data?.[0]?.attributes?.title}
          isSmall={false}
          id={data?.[0]?.id}
        />
      </div>

      {/* Form Đăng ký */}
      <div className="banner_blog_form">
        <RegisterBox />
        <BoxDownload />
      </div>
    </div>
  );
};

export default BannerNews;
