import { Outlet, Link } from "react-router-dom";
import Header from "@/components/Header/Header.jsx";
import Footer from "@/components/Footer/Footer";
const Layout = () => {
  return (
    <>
      <Header />
      <div className="">
        <Outlet />
      </div>

      <Footer />
    </>
  );
};

export default Layout;
