
import { ArticalCardType, ArticleCategoryInterface, ArticleInterface } from '@/interfaces/Article';
import { create, SetState } from 'zustand';

interface State {
    categoryList: ArticleCategoryInterface[] | [];
    articleList: ArticleInterface[] | [];
    articleDetail: ArticleInterface | null;
    relatedArticleList: ArticleInterface[] | [];
    maxPage: number;
}

interface Actions {
    setArticleDetail: (articleDetail: ArticleInterface) => void;
    setCategoryList: (categoryList: ArticleCategoryInterface[]) => void;
    setArticleList: (articleList: ArticleInterface[]) => void;
    setMaxPage: (maxPage: number) => void;
    setRelatedArticleList: (relatedArticleList: ArticleInterface[]) => void;
    reset: () => void;
}

const initialState: State = {
    articleDetail: null,
    categoryList: [],
    articleList: [],
    relatedArticleList: [],
    maxPage: 0,
};

export const useArticleState = create<State & Actions>((set: SetState<State & Actions>) => ({
    ...initialState,
    setArticleDetail: articleDetail => set(() => ({ articleDetail })),
    setCategoryList(categoryList) {
        set(() => ({ categoryList }));
    },
    setArticleList(articleList) {
        set(() => ({ articleList }));
    },
    setMaxPage(maxPage) {
        set(() => ({ maxPage }));
    },
    setRelatedArticleList(relatedArticleList) {
        set(() => ({ relatedArticleList }));
    },
    reset: () => {
        set({});
    },
}));
