import React from "react";
import styles from "./CcqmCungFinHub.module.css";
import BackgroundImage from "@/assets/images/background/chung-chi-quy-mo-cung-finhub.png";
import MobileAppStore from "@/constants/mobile-store";
export default function CcqmCungFinHub() {
  return (
    <div className={styles.container}>
      {/* <img
        className={styles.background}
        src={require("@/assets/images/backgro-chi-quy-mo-cuund/chungng-finhub.png")}
      /> */}
      <div style={{ backgroundImage: `url(${BackgroundImage})` }}>
        <div className={styles.section_one + " container"}>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className={styles.left_container}>
                <div className={styles.title}>
                  <span className="primary-color">Financial</span> For{" "}
                  <span className="primary-color">Life</span>
                </div>

                <div className={styles.desc}>
                  FinHub là nền tảng hỗ trợ thống kê và phân tích hiệu suất các
                  loại chứng chỉ Quỹ mở trên thị trường Việt Nam giúp nhà đầu tư
                  đưa ra đánh giá và quyết định đúng đắn.
                </div>
                <div className="row gy-3">
                  <div className="col-xs-12 col-md-6">
                    <a href={MobileAppStore.ANDROID} target="_blank">
                      <img
                        src={
                          require("@/assets/images/icon/svg/google-play.svg")
                            .default
                        }
                        alt="google-play"
                      />
                    </a>
                  </div>

                  <div className="col-xs-12 col-md-6">
                    <a href={MobileAppStore.APPLE} target="_blank">
                      <img
                        src={
                          require("@/assets/images/icon/svg//app-store.svg")
                            .default
                        }
                        alt="app-store"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div>
                <img
                  className="img-fluid"
                  src={require("@/assets/images/picture/png/ccqm-finhub/ccqm-cung-finhub-1.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.section_two + " container"}>
        <div className={styles.main_title}>
          Tính năng mang đến <span className="primary-color">trải nghiệm</span>{" "}
          tốt nhất
        </div>
        <div className="row gy-5">
          <div className="col-xs-12 col-md-6">
            <div className={styles.group}>
              <img
                className={styles.image}
                src={require("@/assets/images/picture/png/ccqm-finhub/mien-phi.png")}
              />
              <div className={styles.sub_group}>
                <div className={styles.title}>Miễn phí</div>
                <div>
                  Khách hàng tạo tài khoản trên FinHub và sử dụng
                  dịch vụ phân tích kỹ thuật và đánh giá hiệu suất của các loại
                  Chứng chỉ Quỹ mở hoàn toàn miễn phí.
                </div>
            </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className={styles.group}>
              <img
                className={styles.image}
                src={require("@/assets/images/picture/png/ccqm-finhub/de-dang.png")}
              />
              <div className={styles.sub_group}>
                <div className={styles.title}>Dễ dàng</div>
                <div>
                  Đăng ký chỉ mất 5 phút và 100% trực tuyến, chỉ cần số điện
                  thoại di động và email cá nhân, khách hàng có thể
                  tận hưởng trải nghiệm dịch vụ của FinHub dễ dàng.
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className={styles.group}>
              <img
                className={styles.image}
                src={require("@/assets/images/picture/png/ccqm-finhub/an-toan.png")}
              />
              <div className={styles.sub_group}>
                <div className={styles.title}>An toàn</div>
                <div>
                  FinHub sử dụng quy trình bảo mật tiêu chuẩn dành cho các giao dịch tài chính với phương châm “An toàn tài khoản của bạn là trách nhiệm của chúng tôi”. Mọi thông tin của khách hàng đều được bảo mật.
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className={styles.group}>
              <img
                className={styles.image}
                src={require("@/assets/images/picture/png/ccqm-finhub/da-dang.png")}
              />
              <div className={styles.sub_group}>
                <div className={styles.title}>Đa dạng</div>
                <div>
                  Khách hàng có thể xem được thông tin chi tiết về
                  chiến lược đầu tư, tỷ trọng ngành và tài sản, phí giao dịch
                  hay chỉ số tăng trưởng của các loại Quỹ mở uy tín ngay trên
                  ứng dụng FinHub.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
