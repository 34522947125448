// Header.js
import React from "react";
import styles from "./Home.css";
import CarouselBanner from "@/components/Carousel/Carousel";
import ContentBlock from "@/components/ContentBlock/ContentBlock";
import { Container, Row, Col } from "react-bootstrap";
import Partner from "@/components/Partner/Partner";
import { Link } from "react-router-dom";
const Home = () => {
  const data = [
    {
      title: "Chứng chỉ quỹ mở",
      desc: "FinHub hỗ trợ thống kê và phân tích hiệu suất quỹ mở của các công ty quản lý quỹ hàng đầu Việt Nam.",
      image: "home-image-3.jpg",
      path: "/product/chung-chi-quy-mo",
    },
    {
      title: "Công cụ",
      desc: "FinHub cung cấp đa dạng các công cụ để người dùng có thể dễ dàng đưa ra quyết định phù hợp với nhu cầu và khả năng của mình.",
      image: "home-image-1.jpg",
      path: "/cong-cu/mo-phong-dau-tu-quy-mo",
    },
    {
      title: "Kiến thức",
      desc: "Finhub cung cấp nguồn kiến thức tài chính toàn diện từ cơ bản đến nâng cao giúp người dùng trang bị các kiến thức cần thiết.",
      image: "home-image-2.jpg",
      path: "/kien-thuc",
    },

  ];
  return (
    <div className="d-grid">
      <CarouselBanner />
      <div className="container mb-5 home-container">
        <div className="row gy-5">
          {data.map((item, index) => {
            return (
              <Col key={index} xs={12} md={4} as={Link} to={item.path}>
                <ContentBlock data={item} />
              </Col>
            );
          })}

          {/* <Col xs={12} md={6}>
            <ContentBlock />
          </Col> */}
        </div>

        <div className="row">
          <Partner />
        </div>
      </div>
    </div>
  );
};

export default Home;
