import { Link } from "react-router-dom/dist";
import "./Product.css";
import styles from './Product.module.css'
function Product() {
  return (
    <>
      <div>
        <div>
          <p className="about-title primary-color">Sản phẩm</p>
        </div>
        <div>
          <p>
            Với mục tiêu thúc đẩy tài chính toàn diện, FinHub tập trung vào 3
            mảng chính: Chứng chỉ quỹ mở, Công cụ hỗ trợ quyết định tài chính và Kiến thức tài chính. FinHub là nền tảng tiên phong trong việc kết
            hợp linh hoạt các công cụ hỗ trợ quyết định tài chính và kiến thức
            tài chính cho người dùng.
          </p>
        </div>

        <div className="about-product-image">
          <div className="about-product-card card-right">
            <div className="primary-color about-product-card-title">
              Công cụ
            </div>

            <div>
              <p className="about-product-card-text">
                Cung cấp đa dạng công cụ hỗ trợ quyết định tài chính giúp người
                dùng có thể xây dựng kế hoạch phù hợp với nhu cầu và khả năng
                của mình.
              </p>
            </div>
            <div>
              <Link
                to={"/cong-cu"}
                style={{ marginTop: "unset" }}
              >
                <p
                  style={{
                    color: "#FF8700",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  Xem chi tiết {">"}
                </p>
              </Link>
            </div>
          </div>

          <div className="about-product-card card-bottom">
            <div className="primary-color about-product-card-title">
              Kiến thức
            </div>

            <div>
              <p>
                Là người đồng hành cùng bạn trên hành trình tài chính, giúp bạn
                trang bị đầy đủ kiến thức trước khi hiện thực hóa kế hoạch tài
                chính của mình.
              </p>
            </div>
            <div>
              <Link to={"/kien-thuc"} style={{ marginTop: "unset" }}>
                <p
                  style={{
                    color: "#FF8700",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  Xem chi tiết {">"}
                </p>
              </Link>
            </div>
          </div>

          <div className="about-product-card card-left">
            <div className="primary-color about-product-card-title">
              Chứng chỉ quỹ mở
            </div>

            <div>
              <p>
                Chứng chỉ quỹ mở là công cụ đầu tư linh hoạt và tiện lợi trong
                thế giới tài chính hiện đại, phù hợp với nhà đầu tư mong muốn tham gia mà không cần trực tiếp quản lý tài khoản.
              </p>
            </div>
            <div>
              <Link
                to={"/product/chung-chi-quy-mo"}
                style={{ marginTop: "unset" }}
              >
                <p
                  style={{
                    color: "#FF8700",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  Xem chi tiết {">"}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
