import "./Latest.css";
import React from "react";
import Layout from "../_components/Layout/Layout";
import Card from "../_components/Card/Card";
import { listFeaturedArticle } from "../_components/FeaturedArticle/FeaturedArticle";
import Classify from "../_components/Classify/Classify";
import { useGetAllPost } from "@/hook/blog/useGetAllPost";
import Loading from "../_components/Loading/Loading";

const Latest = () => {
  const { data, loading } = useGetAllPost();

  if (loading) {
    return <Loading />;
  }

  return (
    <Layout listOutstandingBox={listFeaturedArticle.slice(1)}>
      <div className="latest_page">
        <Card
          key={data?.[0]?.id}
          thumbnail={data?.[0]?.attributes?.thumbnail?.data?.attributes?.url}
          category={data?.[0]?.attributes?.sub_category?.data?.attributes?.name}
          author={data?.[0]?.attributes?.author}
          createdAt={data?.[0]?.attributes?.createdAt}
          description={data?.[0]?.attributes?.short_description}
          title={data?.[0]?.attributes?.title}
          isSmall={false}
          id={data?.[0]?.id}
        />

        {/* Classify */}

        <Classify list={listFeaturedArticle} />
      </div>
    </Layout>
  );
};

export default Latest;
