import React from "react";
import Layout from "../_components/Layout/Layout";
import { listFeaturedArticle } from "../_components/FeaturedArticle/FeaturedArticle";
import Classify from "../_components/Classify/Classify";
import { useGetDetails } from "@/hook/blog/useGetDetails";
import Ricktext from "../_components/Ricktext/Ricktext";
import Loading from "../_components/Loading/Loading";

const Details = () => {
  const { data, loading } = useGetDetails();

  if (loading) {
    return <Loading />;
  }
  return (
    <Layout>
      <Ricktext content={data?.attributes?.content_2_3} />
      <Classify list={listFeaturedArticle.slice(1)} />
    </Layout>
  );
};

export default Details;
