import React from "react";

import "./FeaturedArticle.css";
import Card from "../Card/Card";
import { useGetAllPost } from "@/hook/blog/useGetAllPost";

export const listFeaturedArticle = [];

const FeaturedArticle = () => {
  const { data } = useGetAllPost();

  return (
    <div className="featured_article container">
      {/* Left */}
      <div className="featured_article_left">
        <Card
          key={data?.[0]?.id}
          thumbnail={data?.[0]?.attributes?.thumbnail?.data?.attributes?.url}
          category={
            data?.[0]?.attributes?.category_name?.data?.[0]?.attributes?.name
          }
          author={data?.[0]?.attributes?.author}
          createdAt={data?.[0]?.attributes?.createdAt}
          description={data?.[0]?.attributes?.short_description}
          title={data?.[0]?.attributes?.title}
          id={data?.[0]?.id}
          isSmall={false}
        />
      </div>

      {/* Right */}
      <div className="featured_article_right">
        {data?.slice(1, 5).map((item) => (
          <Card
            key={item?.id}
            thumbnail={item?.attributes?.thumbnail?.data?.attributes?.url}
            category={
              data?.[0]?.attributes?.category_name?.data?.[0]?.attributes?.name
            }
            author={item?.attributes?.author}
            createdAt={item?.attributes?.createdAt}
            description={item?.attributes?.short_description}
            title={item?.attributes?.title}
            isSmall={true}
            id={item?.id}
          />
        ))}
      </div>
    </div>
  );
};

export default FeaturedArticle;
