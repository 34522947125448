import React from "react";
import RelatedArticleCard from "../RelatedArticleCard";
import { articles } from "@/data/article";
import styles from "./style.module.css";
import { useTransition, animated } from "react-spring";
import { useArticleState } from "@/stores/useArticleState";

const RelatedArticlesList = React.memo(function RelatedArticlesList() {
  console.log("RelatedArticlesList");
  const {relatedArticleList} = useArticleState();
  const transitions = useTransition(relatedArticleList.slice(0,5), {
    from: { opacity: 0, transform: "translate3d(0,-40px,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0px,0)" },
    leave: { opacity: 0, transform: "translate3d(0,-40px,0)" },
    trail: 100, // Delay between each item
  });

  return transitions((style, article) => (
    <animated.div style={style}>
      <RelatedArticleCard key={article.id} article={article} />
    </animated.div>
  ));

});
export default RelatedArticlesList;
