import React from "react";

import "./TitleBorderGradient.css";

const TitleBorderGradient = ({ title, className }) => {
  return (
    <div className="title_border_gradient">
      <p className={className}>{title}</p>
    </div>
  );
};

export default TitleBorderGradient;
