import Carousel from "react-multi-carousel";
import { Container, Row, Col } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import "./Partner.css";

function Partner() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <img
        src={require("@/assets/images/icon/svg/carousel-right.svg").default}
        style={{ position: "absolute", right: 0, cursor: "pointer" }}
        onClick={() => onClick()}
      />
    );
  };
  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <img
        src={require("@/assets/images/icon/svg/carousel-left.svg").default}
        style={{ position: "absolute", left: 0, cursor: "pointer" }}
        onClick={() => onClick()}
      />
    );
  };
  return (
    <Container fluid className="d-grid gap-3">
        <Row className="text-center d-flex align-items-center">
          <Col xs={4} md={5} >
            <div className="d-flex justify-content-end">
                <div className="partner-hr" />
            </div>
            
          </Col>
          <Col xs={4} md={2} className="p-0">
          <span className="partner-title">ĐƠN VỊ HỖ TRỢ</span>
          </Col>
          <Col xs={4} md={5 }>
          <div className="d-flex justify-content-start">
                <div className="partner-hr" />
            </div>
          </Col>
        </Row>
      <Carousel
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        pauseOnHover
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        // customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        //   deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        rewind={false}
        rewindWithAnimation={false}
        shouldResetAutoplay
      >
        <div className="d-flex justify-content-center">
          <img
            src={require("@/assets/images/picture/png/partner1.png")}
            height={70}
          />
        </div>
        <div className="d-flex justify-content-center">
          <img
            src={require("@/assets/images/picture/png/partner2.png")}
            height={70}
          />
        </div>
        <div className="d-flex justify-content-center">
          <img
            src={require("@/assets/images/picture/png/partner3.png")}
            height={70}
          />
        </div>
        <div className="d-flex justify-content-center">
          <img
            src={require("@/assets/images/icon/png/techcombank.png")}
            height={70}
          />
        </div>
        <div className="d-flex justify-content-center">
          <img
            src={require("@/assets/images/icon/jpg/google-workspace-logo.jpg")}
            height={70}
          />
        </div>
      </Carousel>
    </Container>
  );
}

export default Partner;
