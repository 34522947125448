import React from "react";

import BannerBlog from "@/assets/images/picture/jpg/banner_blog.jpg";
import RegisterBox from "@/components/RegisterBox/RegisterBox";
import BoxDownload from "@/components/BoxDownload/BoxDownload";

import "./Banner.css";

const Banner = () => {
  return (
    <div className="banner_blog container">
      {/* Banner Image */}
      <div className="banner_blog_box_image">
        {/* Title */}
        <div>
          <span className="display_large gradient-text">FinHub Blog </span>
          <span className="display_large">- Định hình tài chính tương lai</span>
        </div>

        <div className="banner_blog_image">
          <img src={BannerBlog} alt="banner blog" />
        </div>
      </div>

      {/* Form Đăng ký */}
      <div className="banner_blog_form">
        <RegisterBox />

        <BoxDownload />
      </div>
    </div>
  );
};

export default Banner;
