import { Link } from "react-router-dom";
import "./ChungChiQuyMo.css";
import Carousel from 'react-bootstrap/Carousel';
import CarouselBanner from "@/components/Carousel/Carousel";
function ChungChiQuyMo() {
  return (
    <>

<Carousel data-bs-theme="light">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('@/assets/images/picture/jpg/ccm-slide-1.jpg')}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('@/assets/images/picture/jpg/ccm-slide-2.jpg')}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('@/assets/images/picture/jpg/ccm-slide-3.jpg')}
          alt="Third slide"
        />
      </Carousel.Item>

    </Carousel>
      {/* <CarouselBanner /> */}
      <div className="container text-center my-5">
        <div className="row">
          <div>
            <p className="product-title primary-color">Chứng chỉ quỹ mở</p>
          </div>
        </div>
        <div className="row mt-5 gy-5">
          <div className="col-xs-12 col-md-4 mx-auto">
            <div className="d-grid gap-3 product-card-container">
              <div>
                <img
                  className="product-card-img"
                  src={require("@/assets/images/picture/png/product-ccqm1.png")}
                />
              </div>
              <div className="product-card-title">
                Giới thiệu chứng chỉ quỹ mở
              </div>

              <div>
                Khám phá chứng chỉ quỹ mở: Tổng quan và cách thức hoạt động
              </div>

              <div className="more-info-btn">
                <Link to="/product/chung-chi-quy-mo/detail">
                  <button className="product-card-btn">
                    {"Tìm hiểu thêm >"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-4 mx-auto">
            <div className="d-grid gap-3 product-card-container">
              <div>
                <img
                  className="product-card-img"
                  src={require("@/assets/images/picture/png/product-ccqm2.png")}
                />
              </div>
              <div className="product-card-title">
                Chứng chỉ quỹ mở cùng FinHub
              </div>

              <div>
                FinHub - Nền tảng đáng tin cậy cung cấp thông tin chứng chỉ quỹ
                mở
              </div>
              <div className="more-info-btn">
                <Link to="/product/chung-chi-quy-mo/ccqm-cung-finhub">
                  <button className="product-card-btn">
                    {"Tìm hiểu thêm >"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="col-xs-12 col-md-3">
            <div className="d-grid gap-3 product-card-container">
              <div>
                <img
                  className="product-card-img"
                  src={require("@/assets/images/picture/png/product-ccqm3.png")}
                />
              </div>
              <div className="product-card-title">Mô phỏng đầu tư</div>

              <div>
                FinHub - nơi bạn có thể trải nghiệm mô phỏng đầu tư chứng chỉ
                quỹ mở 1 cách thực tế
              </div>
              <div className="more-info-btn">
                <Link to="/product/chung-chi-quy-mo/mo-phong-dau-tu-quy-mo">
                  <button className="product-card-btn">
                    {"Tìm hiểu thêm >"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="d-grid gap-3 product-card-container">
              <div>
                <img
                  className="product-card-img"
                  src={require("@/assets/images/picture/png/product-ccqm3.png")}
                />
              </div>
              <div className="product-card-title">Robo Advisor</div>

              <div>Tư vấn tư động danh mục phù hợp với bạn</div>
              <div className="more-info-btn">
                <Link to="/product/chung-chi-quy-mo/robo-advisor">
                  <button className="product-card-btn">
                    {"Tìm hiểu thêm >"}
                  </button>
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ChungChiQuyMo;
