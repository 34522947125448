import React from "react";
import BannerNews from "../_components/Banner/BannerNews";
import Loading from "../_components/Loading/Loading";
import Bannerblognew from "@/assets/images/picture/jpg/banner_blog_new.jpg";

import "./News.css";
import ListNews from "./ListNews";
import { useGetAllPostNews } from "@/hook/blog/useGetAllPostNews";

const News = () => {
  const { data, loading } = useGetAllPostNews();

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="news_page">
      <BannerNews
        data={data?.attributes?.bai_viets?.data}
        category={data?.attributes?.name}
      />

      <div className="container mt-5">
        <ListNews data={data?.attributes?.bai_viets?.data} />
      </div>
    </div>
  );
};

export default News;
