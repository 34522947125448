import React from "react";

import "./GroupBoxNavigateWhite.css";
import { useNavigate } from "react-router-dom";
import { useGetPathname } from "@/utils/getPathname";

const GroupBoxNavigateWhite = ({ list = [] }) => {
  const { dm1, queryDM1 } = useGetPathname();

  const navigate = useNavigate();

  const navigateToDM2 = (dm2, id) => {
    navigate(`/blog/${dm1}/${dm2}?type=${queryDM1}&type-dm2=${id}`);
  };

  return (
    <div className="group_box_navigate_white">
      {list?.map((item, i) => (
        <div
          key={i}
          className="group_box_navigate_white_item "
          onClick={() => {
            navigateToDM2(item?.attributes?.name, item?.id);
          }}
        >
          <p className="title_large color_primary text_center">{item?.attributes?.name}</p>
        </div>
      ))}
    </div>
  );
};

export default GroupBoxNavigateWhite;
