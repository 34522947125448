import BreadcrumbComponent from "@/components/Breadcrumb/Breadcrumb";
import "./RoboAdvisor.css";
import styles from "./RoboAdvisor.module.css";
function RoboAdvisor() {
  const breadcrumbItems = [
    { text: "Trang chủ", href: "/" },
    { text: "Công cụ", href: "/cong-cu" },
    { text: "Robo Advisor", href: "/cong-cu/robo-advisor" },
  ];
  return (
    <>
      <div className={styles.root_container}>
      <div className="container"><BreadcrumbComponent breadcrumbItems={breadcrumbItems} /></div>
        <div className="container d-grid gap-4">
          <div className={styles.title_container}>
            <p className={styles.title}>
              Đầu tư <br /> an toàn hơn, tối đa hoá lợi nhuận
            </p>
          </div>

          <div className="row gx-5">
            <div className="col-xs-12 col-md-6 mt-3">
              <div className="text-start">
                <p>
                  Nếu bạn không tự tin trong việc đưa ra quyết định đầu tư, bạn
                  cần sự tư vấn,{" "}
                  <span className="primary-color">Robo Advisor</span> sẽ giúp
                  bạn đầu tư với chiến lược tối ưu. Chúng tôi cam kết cung cấp:
                </p>

                <ul>
                  <li>
                    <strong>Tư vấn</strong> đầu tư minh bạch
                  </li>
                  <li>
                    <strong>Khuyến nghị</strong> lựa chọn sản phẩm đầu tư một
                    cách độc lập, không chịu tác động từ bất kỳ bên nào khác
                  </li>
                  <li>
                    Thuật toán phân tích được xây dựng{" "}
                    <strong>
                      dựa trên lý thuyết danh mục đầu tư hiện đại và kinh nghiệm
                      của các nhà phân tích cao cấp
                    </strong>
                  </li>
                </ul>

                <img className="img-fluid" src={require('@/assets/images/picture/png/product-robo-advisor-1.png')}/>
              </div>
            </div>

            <div className="col-xs-12 col-md-6 mt-3">
              <img className="img-fluid"
              src={require("@/assets/images/picture/png/product-robo-advisor.png")} />
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
}

export default RoboAdvisor;
