import React from "react";
import axiosInstance from "../axios";

export const getCategoryArticle = async () => {
  return (await axiosInstance.get("/admin/pressmedia/category"))
    .data;
};

export const getAllArticle = async (page: number, limit = 5) => {
  return (await axiosInstance.get("/admin/pressmedia/listpressmedia?page=" + page + "&limit=" + limit))
    .data;
};

export const getDetailArticle = async (threadId: React.Key) => {
  return (await axiosInstance.get(`/admin/pressmedia/pressmedia/${threadId}`))
    .data;
};

export const getArticleByCategory = async (page: number, category_id: number, limit = 5) => {
  return (await axiosInstance.get(`/admin/pressmedia/pressmedia-by-categoryid?category_id=${category_id}&page=${page}&limit=${limit}`))
    .data;
};

export const getRelatedArticles = async (threatId: React.Key) => {
  return (await axiosInstance.get(`/admin/pressmedia/relatedthred?thread_id=${threatId}`))
    .data;
};

