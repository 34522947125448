import React, { useState } from "react";

import "./RegisterBox.css";
import { userRegister } from "@/services/blog/blog";
import Loading from "../Blog/_components/Loading/Loading";
import { images } from "@/utils/iamges";

const { QrCode } = images

const RegisterBox = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMess, setErrMess] = useState("");

  const validate = () => {
    if (!email) {
      setErrMess("Nhập đầy đủ email");
      return true;
    }
    setErrMess("");
    return false;
  };

  const handeleRegister = async (e) => {
    e.preventDefault();
    const check = validate();
    if (!check) {
      setLoading(true);
      await userRegister({ Email: email });
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="qr_box"
      >

        <img width={300} src={QrCode} alt="qr_app" />
      </div>
    </div>
  );

  // return (
  //   <div>
  //     <div className="register_box bg_primary">
  //       <form onSubmit={handeleRegister}>
  //         <div className="register_box_inner">
  //           {/* Title */}
  //           <p className="headline_medium color_white">
  //             FinHub Blog - Định hình tài chính tương lai
  //           </p>

  //           {/* Form */}

  //           <div className="register_box_form">
  //             <label htmlFor="name" className="text_regular color_white">
  //               Đăng ký để được nhận thư cập nhật từ chúng tôi
  //             </label>
  //             <input
  //               type="text"
  //               name="email"
  //               id="name"
  //               placeholder="Email của bạn"
  //               value={email}
  //               onChange={(e) => setEmail(e.target.value)}
  //             />
  //           </div>
  //           <span style={{ fontSize: "11px", color: "red" }}>
  //             {!!errMess && errMess}
  //           </span>
  //           <div className="btn_register_box">
  //             <button type="submit" className="btn_gradient">
  //               {loading ? <Loading /> : " Đăng ký"}
  //             </button>
  //           </div>
  //         </div>
  //       </form>
  //     </div>
  //   </div>
  // );
};

export default RegisterBox;
