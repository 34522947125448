import { icons } from "@/themes/images";
import React, { useState } from "react";
import styles from "./style.module.css";
import { ReactComponent as ArrowDownIcon } from "@/assets/images/icon/ic_arrow_down.svg";
import { ReactComponent as ArrowDownGradientIcon } from "@/assets/images/icon/ic_arrow_down_gradient.svg";

interface LoadMoreProps {
  onLoadMore: () => void;
}
export default function LoadMore({onLoadMore}: LoadMoreProps) {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      className={styles.container}
      onClick={onLoadMore}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <span className={styles.text}>Hiển thị thêm</span>
      {hovered ? (
        <ArrowDownIcon className={styles.icon} />
      ) : (
        <ArrowDownGradientIcon className={styles.icon} />
      )}
    </div>
  );
}
