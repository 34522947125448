import { useMutation } from "@tanstack/react-query";
import { getCategoryArticle, getDetailArticle, getRelatedArticles } from "../apiFunctions/article.api";
import { useArticleState } from "@/stores/useArticleState";



export const useGetCategoryArticle = () => {
  const {setCategoryList} = useArticleState();
  return useMutation({
    mutationFn: () => getCategoryArticle(),
    onSuccess: ({data}) => {
      setCategoryList(data.data.category);
    },
    onError: (error) => {},
  });
};

export const useGetRelatedArticles = () => {
  const {setRelatedArticleList} = useArticleState();
  return useMutation({
    mutationFn: (threadId: React.Key) => getRelatedArticles(threadId),
    onSuccess: ({data}) => {
      console.log(data.data.thread);
      setRelatedArticleList(data.data.thread);
    },
    onError: (error) => {},
  });
}

export const useGetDetailArticle = () => {
  const {setArticleDetail} = useArticleState();
  return useMutation({
    mutationFn: (threadId: React.Key) => getDetailArticle(threadId),
    onSuccess: ({data}) => {
      setArticleDetail(data.data.thread);
    },
    onError: (error) => {
      console.log(error);
    },
  });
}