import { useEffect, useState } from "react";
import "./ThuatNgu.css";
import styles from "./ThuatNgu.module.css";
import ApiService from "@/services/api.ts";
import BreadcrumbComponent from "@/components/Breadcrumb/Breadcrumb";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Loading from "@/components/Loading/Loading";

const ThuatNgu = () => {
  const [fetching, setFetching] = useState(true);
  const [terminologyList, setTerminologyList] = useState([]);
  const [activeLetter, setActiveLetter] = useState("");
  
  const breadcrumbItems = [
    { text: "Trang chủ", href: "/" },
    { text: "Kiến thức", href: "/kien-thuc" },
    { text: "Thuật ngữ", href: "/kien-thuc/thuat-ngu" },
  ];

  const alphabet = [
    "A", "B", "C", "D", "Đ", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", 
    "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await new ApiService().executeGetRequest(
          "/landing/product/term",
          { letter: activeLetter }
        );

        const sortedData = data.data.data.sort((a, b) => 
          a.term.localeCompare(b.term)
        );
        setTerminologyList(sortedData);
      } catch (error) {
        console.error(error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [activeLetter]);

  const handleAlphabetClick = (letter) => {
    setActiveLetter(letter);
  };

  return (
    <div className={`${styles.root_container} mb-5`}>
      <Container>
        <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />
        <div className="d-grid gap-4 text-center">
          <Row>
            <div className={styles.title_container}>
              <p className={`${styles.title} primary-color`}>Thuật ngữ</p>
            </div>
          </Row>
          <Row>
            <div className={styles.alphabet_container}>
              <span
                onClick={() => handleAlphabetClick("")}
                className={`${styles.alphabet_item} ${!activeLetter ? styles.active : ""}`}
              >
                TẤT CẢ
              </span>
              {alphabet.map((item, index) => (
                <span
                  key={index}
                  onClick={() => handleAlphabetClick(item)}
                  className={`${styles.alphabet_item} ${activeLetter === item ? styles.active : ""}`}
                >
                  {item}
                </span>
              ))}
            </div>
          </Row>
          {fetching ? (
            <Loading />
          ) : (
            <Row className="gy-3">
              {terminologyList.map((item, index) => (
                <Accordion key={index} className="shadow mt-3 rounded-lg box_title_accordion">
                  <Accordion.Header className="box_knowledge">
                    <h4>{item.term}</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text_medium knowledge_body">
                      {item.definition}
                    </p>
                  </Accordion.Body>
                </Accordion>
              ))}
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ThuatNgu;
